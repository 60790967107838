import React from 'react'

import { ReactComponent as InnerRedCircle } from '@percent/cause-dashboard/common/assets/images/modal/inner-red-circle.svg'
import { SuccessModalContainer } from './SuccessModalContainer'
import styles from './SuccessModalContainer.module.scss'
import { ReactComponent as Cross } from '@percent/cause-dashboard/common/assets/images/modal/cross.svg'
import { ReactComponent as OuterRedCircle } from '@percent/cause-dashboard/common/assets/images/modal/outer-red-circle.svg'

type SuccessDeleteModalProps = {
  title: string
  onClose: () => void
  subTitle?: string
}

export function SuccessDeleteModal({ title, onClose, subTitle }: SuccessDeleteModalProps) {
  return (
    <SuccessModalContainer title={title} onClose={onClose} subTitle={subTitle}>
      <OuterRedCircle className={styles.outerRedCircle} />
      <InnerRedCircle className={styles.innerRedCircle} />
      <Cross className={styles.cross} />
    </SuccessModalContainer>
  )
}
