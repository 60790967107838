import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { AuthView } from '../auth/AuthView'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import styles from './LoginPage.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { LoginType } from '@percent/cause-dashboard/services/auth/auth.types'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { RoutePath } from '../../routes/Routes'
import { emailRegex } from '@percent/utility'

const REQUIRED_2FA_ERROR = 'auth/requires_two_fa_setup'
const REQUIRED_2FA_LOGIN = 'auth/requires_two_fa_login'

export function LoginPage() {
  const { authService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { t } = useTranslation()
  const { push } = useHistory()
  const { track, identify } = useCausesPortalAnalytics()

  useEffect(() => {
    // check if that is required, authReducer should handle authorisation, so it can be redundant, but will have to check that after
    // releasing the API
    if (isAuthorised) {
      push('/nonprofits')
    }
  }, [isAuthorised, push])

  const loginAndUpdateUser = (loginDetails: LoginType) =>
    authService
      .login({
        ...loginDetails,
        enforce: true
      })
      .then(async resp => {
        const { data } = resp.data
        const { id } = data

        await identify({ id, traits: { email: loginDetails?.email } })
        await track('Logged In')

        return authDispatch({
          type: SET_AUTHORISED,
          payload: {}
        })
      })
      .catch(error => {
        if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_ERROR) {
          push('/signin/2fa-setup', {
            password: loginDetails.password
          })
        } else if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_LOGIN) {
          push('/signin/2fa', {
            password: loginDetails.password
          })
        } else {
          throw error
        }
      })

  const [{ errorMessage, isLoading }, { apiFunc: loginUser }] = useMutation(loginAndUpdateUser)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        email: yup.string().required('Required').trim().matches(emailRegex, t('errorMessage.validEmail')),
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: (loginDetails: LoginType) => {
      loginUser(loginDetails)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthView causesPortalLogo heading={t('form.signIn')} handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <FormikProvider value={formik}>
          <FormField
            label="Email address"
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
          >
            <TextInput
              name="email"
              placeholder="Enter email address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label="Password"
            status={touched.password && errors.password ? 'danger' : 'default'}
            statusMessage={errors.password}
            data-testid="password"
          >
            <TextInput
              type="password"
              name="password"
              placeholder="Enter your password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          {errorMessage && <Feedback variant="critical">{errorMessage}</Feedback>}
          <Spacer size={5} axis="vertical" />
          <Button size="large" stretch data-testid="auth-active-button" type="submit">
            {t('form.signIn')}
          </Button>
          <Typography variant="body2" className={styles.forgotPassowrdText}>
            <span>
              <Link to="/forgot-password"> {t('form.forgotPassword')}</Link>
            </span>
          </Typography>
          <Typography variant="body2" className={styles.noAccountYet}>
            {t('form.noAccount')}
          </Typography>
          <Typography variant="body2" color="primary" className={styles.loginFooterText}>
            <span>
              <Link data-testid="signup-link" to={RoutePath.CLAIM}>
                {t('form.nonProfitAndSignUp')}
              </Link>
            </span>
          </Typography>
        </FormikProvider>
      )}
    </AuthView>
  )
}
