import React from 'react'
import { Grid } from '@material-ui/core'

import { FieldError } from '../fieldError/FieldError'

import styles from './FieldMeta.module.scss'
import { FieldMetaProps } from './FieldMeta.types'

export function FieldMeta({ characterCount, error }: FieldMetaProps) {
  const justify = characterCount ? 'space-between' : 'flex-start'

  return (
    <Grid container className={styles.textAreaDetail} justifyContent={justify}>
      <Grid item>
        <FieldError error={error} />
      </Grid>
      {characterCount && (
        <Grid item className={styles.counter}>
          <span>
            {characterCount.charCount}/{characterCount.countLimit}
          </span>
        </Grid>
      )}
    </Grid>
  )
}
