import React, { ReactNode } from 'react'
import { Card, CardContent, CardActions, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import styles from './ClaimSearchCardView.module.scss'

type ClaimSearchCardViewProps = {
  title: string
  subTitle: string
  detail: string
  cardAction: ReactNode
}

export function ClaimSearchCardView({ title, subTitle, detail, cardAction }: ClaimSearchCardViewProps) {
  const { t } = useTranslation()

  return (
    <Card className={styles.cardRow}>
      <CardContent>
        <Typography variant="h6" color="secondary">
          {title}
        </Typography>
        <Typography variant="body2">{subTitle}</Typography>
        <Typography variant="body1" color="secondary" className={styles.detailText}>
          {`${t('typography.registeredNumber')} ${detail}`}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardAction}>{cardAction}</CardActions>
    </Card>
  )
}
