import React from 'react'

import { ReactComponent as PoweredByPercentLogo } from '@percent/cause-dashboard/common/assets/images/powered-by-percent-logo.svg'
import { ReactComponent as PoweredByPercentLogoHover } from '@percent/cause-dashboard/common/assets/images/powered-by-percent-logo-hovered.svg'

export function PercentLogo() {
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  const handleMouseMove = () => setIsHovered(prevValue => !prevValue)

  return isHovered ? (
    <PoweredByPercentLogoHover onMouseLeave={handleMouseMove} title="percent-logo-hovered" />
  ) : (
    <PoweredByPercentLogo onMouseEnter={handleMouseMove} title="percent-logo" />
  )
}
