import { string } from 'yup'

export const isValidWebsiteURL = (
  message = 'this must be a valid website URL'
) =>
  string()
    .transform((a) => {
      if (a == null) return a

      if (!/^(?:ht)tps?:\/\//.test(a)) {
        return `http://${a}`
      }

      return a
    })
    .test('is-website-url', message, async function isWebsiteUrl(value) {
      if (value == null) return true
      try {
        await string().url().validate(value)
        const matches = [...value.matchAll(/^(?:https?:\/\/)?([^/?\n]+)/gim)]

        if (matches[0][1].indexOf('@') !== -1) {
          throw new Error()
        }

        return true
      } catch (e) {
        return this.createError({
          path: this.path,
          message: 'Must be a valid website URL',
        })
      }
    })
