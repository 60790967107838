import { Grid, Typography } from '@material-ui/core'

import { config } from '@percent/cause-dashboard/config'
import VerifiedIcon from '@percent/cause-dashboard/common/assets/images/verified-icon.svg'
import { ReactComponent as PercentLogo } from '@percent/cause-dashboard/common/assets/images/percentlogo.svg'
import RectangleSeperator from '@percent/cause-dashboard/common/assets/images/rectangle-seperator.svg'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { AuthBackgroundImage } from '@percent/cause-dashboard/common/components'
import styles from './AuthView.module.scss'
import { AuthViewProps } from './AuthView.types'
import { CausesPortalLogoAndText } from '@percent/cause-dashboard/common/components/causesPortalLogoText/CausesPortalLogoText'
import { AnimatedContainer } from '@percent/lemonade'

export const AuthView = ({
  heading = '',
  children,
  CustomFooter,
  handleSubmit,
  AgentVerificationLogo,
  agentVerificationLink,
  causesPortalLogo = false
}: AuthViewProps) => (
  <Grid
    spacing={0}
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    className={styles.loginPageContainer}
  >
    <>
      <Grid item xs={12}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} className={styles.form}>
            {AgentVerificationLogo && (
              <>
                <div className={styles.logosWrapper}>
                  <a href={agentVerificationLink} target="_blank" rel="noreferrer">
                    <img alt="cause-logo" src={AgentVerificationLogo} className={styles.causeLogo} />
                  </a>
                  <img alt="verified-icon" src={RectangleSeperator} className={styles.rectangleSeperator} />
                  <a href={config.urls.poweredByPercent} target="_blank" rel="noopener noreferrer">
                    <CausesPortalLogoAndText />
                  </a>
                </div>
                <AnimatedContainer>
                  <div className={styles.verifiedIcon}>
                    <img alt="verified-icon" src={VerifiedIcon} />
                  </div>
                </AnimatedContainer>
              </>
            )}
            {(causesPortalLogo && <CausesPortalLogoAndText />) || (!AgentVerificationLogo && <PercentLogo />)}
            <Typography variant="h5" color="secondary" className={styles.loginText} data-testid="auth-view-heading">
              {heading}
            </Typography>
            {children}
          </form>
        </div>
      </Grid>
      <AuthBackgroundImage />
      {CustomFooter ? <CustomFooter /> : <Footer />}
    </>
  </Grid>
)
