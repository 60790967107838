import React from 'react'
import { Typography } from '@material-ui/core'

import { ReactComponent as CausesPortalLogo } from '@percent/cause-dashboard/common/assets/images/causes-portal-icon.svg'
import styles from './CausesPortalLogoText.module.scss'
import { useTranslation } from 'react-i18next'

export function CausesPortalLogoAndText() {
  const { t } = useTranslation()
  return (
    <div className={styles.causePortalLogo}>
      <CausesPortalLogo />
      <Typography color="secondary">{t('typography.causesPortal')}</Typography>
    </div>
  )
}
