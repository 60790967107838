import React, { ReactNode } from 'react'
import { Grid, Typography } from '@material-ui/core'

import styles from './SuccessModalContainer.module.scss'
import { Button } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'

type SuccessModalContainerProps = {
  title: string
  onClose: () => void
  children: ReactNode
  subTitle?: string
}

export function SuccessModalContainer({ title, subTitle, onClose, children }: SuccessModalContainerProps) {
  const { t } = useTranslation()
  return (
    <Grid className={styles.container}>
      <Grid container alignItems="center" direction="column" className={styles.successContainer}>
        {children}
      </Grid>
      <Grid container alignItems="center" direction="column" className={styles.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        <Typography className={styles.subTitle} variant="subtitle2">
          {subTitle}
        </Typography>
      </Grid>
      <Grid container alignItems="center" direction="column" className={styles.doneButtonContainer}>
        <Button size="large" data-testid="modal-add-item-success-button" onPress={onClose}>
          {t('button.done')}
        </Button>
      </Grid>
    </Grid>
  )
}
