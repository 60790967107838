import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './NoResultTable.module.scss'
import { NoResultTableProps } from './NoResultTable.types'

export function NoResultTable({ text, actionsRow, image }: NoResultTableProps) {
  return (
    <div className={styles.noResultTableContainer}>
      {!!image && <img alt="no result" src={image} />}
      <Typography variant="h6" color="secondary">
        {text}
      </Typography>
      {actionsRow}
    </div>
  )
}
