import { Typography } from '@material-ui/core'
import { ZENDESK_TICKET_LINK } from '@percent/cause-dashboard/constants/zendesk'
import { Badge } from '@percent/lemonade'
import React from 'react'

import styles from './BankDetailsReview.module.scss'
import { BankDetailsReviewProps } from './BankDetailsReview.types'

export function BankDetailsReview({
  reviewBadgeText = '',
  reviewText = '',
  reviewLinkText,
  variant,
  icon
}: BankDetailsReviewProps) {
  return (
    <>
      {' '}
      <div className={styles.bankDetailsBadge}>
        <Badge variant={variant} icon={icon}>
          {reviewBadgeText}
        </Badge>
      </div>
      {reviewText && (
        <Typography className={styles.reviewText}>
          {reviewText}{' '}
          <a
            href={`${ZENDESK_TICKET_LINK}?ticket_form_id=14155148527633`}
            target="_blank"
            rel="noreferrer"
            className={styles.formLink}
          >
            {reviewLinkText}
          </a>
          .
        </Typography>
      )}
    </>
  )
}
