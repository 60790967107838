import React from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { ModalContainer, FieldError, Loader } from '@percent/cause-dashboard/common/components'
import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { ReactComponent as Close } from '@percent/cause-dashboard/common/assets/images/close.svg'
import { ReactComponent as LockIcon } from '@percent/cause-dashboard/common/assets/images/lock-icon.svg'
import { ChangeTwoFactorDeviceProps } from './ChangeTwoFactorDevice.types'
import styles from './ChangeTwoFactorDevice.module.scss'
import { useTranslation } from 'react-i18next'

export function ChangeTwoFactorDevice({
  isOpened,
  onClose,
  errorMessage,
  isLoading,
  generateQRToken
}: ChangeTwoFactorDeviceProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      generateQRToken({ password })
    }
  })

  const { errors, values, touched, dirty, isValid, handleChange, handleBlur, handleSubmit } = formik

  return (
    <ModalContainer openModal={isOpened} onClose={onClose} fullscreen>
      <button className={styles.close} type="button" data-testId="close-button" onClick={onClose}>
        <Close />
      </button>
      <div className={styles.passwordContainer}>
        <div>
          <LockIcon className={styles.lockIcon} />
          <Typography variant="h5" className={styles.headerOne}>
            {t('2fa.enable2fa')}
          </Typography>
          <Typography variant="body1" className={styles.headerTwo}>
            {t('2fa.enable2faTitle')}
          </Typography>
          <form onSubmit={handleSubmit} className={styles.passwordForm}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <FormikProvider value={formik}>
                  <FormField
                    label={t('2fa.passwordLabel')}
                    status={touched.password && errors.password ? 'danger' : 'default'}
                    statusMessage={errors.password}
                    data-testid="password"
                  >
                    <TextInput
                      type="password"
                      name="password"
                      placeholder={t('2fa.passwordPlaceholder')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </FormField>
                </FormikProvider>
                <div>{errorMessage && <FieldError error={errorMessage} />}</div>
                <Spacer size={6} axis="vertical" />
                <Button disabled={!(isValid && dirty)} type="submit">
                  {t('button.setUp')}
                </Button>
              </>
            )}
          </form>
        </div>
      </div>
    </ModalContainer>
  )
}
