import { Divider, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import { config } from '@percent/cause-dashboard/config'

type FooterProps = {
  noTermsAndPrivacyPolicy?: boolean
}

export function Footer({ noTermsAndPrivacyPolicy = false }: FooterProps) {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <Divider />
      <Typography variant="body2" className={styles.typography}>
        <span role="img">©</span> {new Date().getFullYear()}
        &nbsp;
        <a target="_blank" rel="noreferrer" href={config.urls.poweredByPercent} className={styles.poweredByPercent}>
          {t('typography.poweredByPercent')}.
        </a>
        &nbsp;
        {t('typography.allRightsReserved')}. &nbsp;
        {!noTermsAndPrivacyPolicy && (
          <span className={styles.termsOfUse}>
            <a target="_blank" rel="noreferrer" href={config.urls.terms}>
              {t('typography.termsOfUse')}
            </a>
          </span>
        )}
      </Typography>
    </footer>
  )
}
