import { TableCell, TableRow } from '@material-ui/core'
import { Loader } from '@percent/cause-dashboard/common/components'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import styles from './UsersListTable.module.scss'
import { Badge } from '@percent/lemonade'
import { IamGetAccounts } from '@percent/cause-dashboard/services/types/iamResponses.types'
import { useState } from 'react'
import { EditUserRoleModal } from './EditUserRoleModal/EditUserRoleModal'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { usePermissionCheck } from '@percent/cause-dashboard/common/hooks/usePermissionCheck/usePermissionCheck'
import { useTranslation } from 'react-i18next'

interface UsersListTableProps {
  isLoading: boolean
  data: IamGetAccounts[] | null
  totalResultCount: number
  onNextPageClick: (() => void) | null
  onPreviousPageClick: (() => void) | null
  onRefetchData?: () => void
}

export function UsersListTable({
  data,
  isLoading,
  totalResultCount,
  onNextPageClick: nextPage,
  onPreviousPageClick: previousPage,
  onRefetchData
}: UsersListTableProps) {
  const { t } = useTranslation()
  const [currentEditModalUserId, setCurrentEditModalUserId] = useState<string>('')

  const {
    authState: { user: currentUser }
  } = useAuthState()
  const { userCan } = usePermissionCheck()

  const canEditRoles = userCan('organization_users:write')

  if (isLoading) {
    return <Loader />
  }

  const handleRoleChanged = () => {
    setCurrentEditModalUserId('')
    onRefetchData?.()
  }

  const columns = [
    { id: 'typography.userManagement.email', isSortable: false },
    { id: 'typography.userManagement.role', isSortable: false },
    ...(canEditRoles ? [{ id: 'typography.userManagement.actions', isSortable: false }] : [])
  ]

  return (
    <Table
      data={data}
      isLoading={isLoading}
      totalResults={totalResultCount || 0}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t('table.noUsers')}
      orderBy=""
    >
      <div data-testid={'users-table'} />
      {data?.map(({ id, email, role }) => (
        <>
          <EditUserRoleModal
            accountId={id}
            email={email}
            role={role}
            open={currentEditModalUserId === id}
            close={() => setCurrentEditModalUserId('')}
            onRoleChange={handleRoleChanged}
          />
          <TableRow key={`permissions-list-${id}`} data-testid={`row-${id}`}>
            <TableCell>
              <div className={styles.cellWrapper}>
                <span>{email}</span>
              </div>
            </TableCell>
            <TableCell data-testid={`row-${id}-role-${role.displayName}`}>
              <Badge variant="default" testId="role-badge">
                {role.displayName}
              </Badge>
            </TableCell>
            {currentUser?.id !== id && canEditRoles && (
              <TableCell>
                <span
                  className={styles.underlinedText}
                  onClick={() => {
                    setCurrentEditModalUserId(id)
                  }}
                  role="button"
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setCurrentEditModalUserId(id)
                    }
                  }}
                >
                  {t('typography.userManagement.edit')}
                </span>
              </TableCell>
            )}
          </TableRow>
        </>
      ))}
    </Table>
  )
}
