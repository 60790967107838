import { useTranslation } from 'react-i18next'
import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import styles from '../BankDetails.module.scss'
import { FormikProvider, useFormik } from 'formik'
import { BankDetailsViewProps } from './BankDetailsView.types'
import { BankDetailStatus } from '@percent/cause-dashboard/services/bankDetails/bankDetails.types'
import { bankDetailsViewSchema } from './BankDetailsViewSchema'
import { UploadDocument } from '../uploadDocument/UploadDocument'

export function BankDetailsView({
  setSelectedFile,
  buttonFlag,
  disableUploadBtn,
  disabledField,
  bankDetailsData,
  selectedFile,
  apiFunc,
  fileFieldText,
  isPostLoading
}: BankDetailsViewProps) {
  const { t } = useTranslation()
  const showIbanDetails = bankDetailsData?.requiredBankAccountDetails?.type === 'IBAN'
  const firstField = showIbanDetails ? 'bic' : 'bankCode'
  const secondField = showIbanDetails ? 'iban' : 'accountNumber'
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      holderName:
        bankDetailsData?.status !== BankDetailStatus.REJECTED ? bankDetailsData?.bankAccountDetails?.holderName : '',
      [firstField]:
        bankDetailsData?.status !== BankDetailStatus.REJECTED
          ? bankDetailsData?.bankAccountDetails?.wireTransferDetails?.[firstField]
          : '',
      [secondField]:
        bankDetailsData?.status !== BankDetailStatus.REJECTED
          ? bankDetailsData?.bankAccountDetails?.wireTransferDetails?.[secondField]
          : ''
    },
    validationSchema: () =>
      bankDetailsViewSchema({
        t,
        firstField,
        secondField,
        bankDetailsData
      }),
    onSubmit: value => {
      const formData = new FormData()
      const bankAccountDetails = {
        holderName: value.holderName,
        paymentDetails: {
          type: showIbanDetails ? 'iban' : 'Domestic',
          [firstField]: value[firstField],
          [secondField]: value[secondField]
        }
      }
      if (selectedFile) {
        formData.append('proofOfBankDetails', selectedFile)
        formData.append('bankAccountDetails', JSON.stringify(bankAccountDetails))
      }

      apiFunc({
        payload: {
          formData
        }
      })
    }
  })

  const { errors, values, touched, dirty, handleChange, handleBlur, handleSubmit, isValid } = formik

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Spacer size={4} axis="vertical" />
        <>
          <FormField
            label="Account holder name"
            status={touched.holderName && errors.holderName ? 'danger' : 'default'}
            statusMessage={errors.holderName}
            disabled={disabledField}
            description="Legal name associated with the bank account. Note we don’t accept personal bank accounts."
            data-testid="holderName"
            necessity="required"
          >
            <TextInput
              name="holderName"
              value={values.holderName}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]?.label}
            status={touched?.[firstField] && errors?.[firstField] ? 'danger' : 'default'}
            statusMessage={errors?.[firstField]}
            description={bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]?.hint}
            disabled={disabledField}
            data-testid={firstField}
            necessity="required"
          >
            <TextInput
              name={firstField}
              value={values?.[firstField]}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]?.label}
            status={touched[secondField] && errors[secondField] ? 'danger' : 'default'}
            statusMessage={errors?.[secondField]}
            description={bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]?.hint}
            disabled={disabledField}
            data-testid={secondField}
            necessity="required"
          >
            <TextInput
              name={secondField}
              value={values?.[secondField]?.toUpperCase()}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
        </>
      </FormikProvider>
      <Spacer axis="vertical" size={5} />
      <UploadDocument
        helperText={t('typography.helperBankAccountText')}
        labelText={t('typography.proofBankAccount')}
        uploadButton={t('button.uploadDocument')}
        fileFieldText={fileFieldText}
        setSelectedFile={setSelectedFile}
        buttonFlag={buttonFlag}
        disableUploadBtn={disableUploadBtn}
        inputDisableStyle={disabledField}
      />
      {buttonFlag && (
        <div className={styles.submitFileButton}>
          <Button
            type="submit"
            variant={selectedFile && isValid && dirty && !isPostLoading ? 'primary' : 'secondary'}
            disabled={!(selectedFile && isValid && dirty && !isPostLoading)}
          >
            {t('button.submitBankDocuments')}
          </Button>
        </div>
      )}
    </form>
  )
}
