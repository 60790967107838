import { useState } from 'react'

import { useToast } from '@percent/cause-dashboard/containers/ToastContext'
import { APIErrorHandler } from '../../library/APIErrorHandler'

import { FetchDonationCSVType, UseCSVDownloaderPropsType } from './useCSVDownloader.types'

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
const waitAtleast =
  (ms: number) =>
  <R>(f: () => Promise<R>) =>
    Promise.all([f(), sleep(ms)]).then(([result]) => result)
const waitAtleast1Sec = waitAtleast(1000)

export const useCSVDownloader = ({ service, fileName, successMessage, errorMessage }: UseCSVDownloaderPropsType) => {
  const [loading, setLoading] = useState(false)
  const [errorCSVMessage, setErrorCSVMessage] = useState('')
  const addToast = useToast()

  const downloadCsv = (data: string, fileName: string): void => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const fetchDonationCSV = async (csvParamData: FetchDonationCSVType) => {
    setLoading(true)
    setErrorCSVMessage('')
    try {
      const { startDate, endDate } = csvParamData
      const csvData = await waitAtleast1Sec(() =>
        service({
          startDate: startDate,
          endDate
        })
      )
      downloadCsv(csvData.data, fileName?.(startDate, endDate) as string)
      addToast(successMessage, 'success')
    } catch (err: any) {
      setErrorCSVMessage(APIErrorHandler(err?.response?.data?.error))
    } finally {
      setLoading(false)
    }
  }

  const fetchPayoutDonationsCSV = async () => {
    setLoading(true)
    setErrorCSVMessage('')
    try {
      const csvData = await waitAtleast1Sec(() => service())
      downloadCsv(csvData.data, csvData.fileName as string)
      addToast(successMessage, 'success')
    } catch (err: any) {
      setErrorCSVMessage(APIErrorHandler(err?.response?.data?.error))

      if (errorMessage) {
        addToast(errorMessage, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  return [
    {
      loading,
      errorCSVMessage
    },
    {
      fetchDonationCSV,
      fetchPayoutDonationsCSV
    }
  ]
}
