import { AcknowledgeModal } from '@percent/lemonade'
import { RoutePath } from '../../routes/Routes'
import { useHistory } from 'react-router-dom'
import styles from './SignUpConfirmation.module.scss'
import { useTranslation } from 'react-i18next'

export function SignUpConfirmation() {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={styles.modal}>
      <AcknowledgeModal
        result={'positive'}
        title={t('typography.signupConfirmation.title')}
        buttonText={t('button.signIn')}
        handleClose={() => history.push(RoutePath.SIGNIN)}
      >
        {t('typography.signupConfirmation.body')}
      </AcknowledgeModal>
    </div>
  )
}
