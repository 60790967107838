import { CausesPortalLogoAndText } from '@percent/cause-dashboard/common/components/causesPortalLogoText/CausesPortalLogoText'
import { Heading, Spacer, Text } from '@percent/lemonade'
import React from 'react'

import { ReactComponent as Letterbox } from '@percent/cause-dashboard/common/assets/images/letterbox.svg'
import { useTranslation } from 'react-i18next'
import styles from './ConfirmationView.module.scss'

export const ConfirmationView = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.confirmationViewContainer}>
      <CausesPortalLogoAndText />
      <Spacer size={12} axis="vertical" />
      <Letterbox />
      <Spacer size={8} axis="vertical" />
      <Heading level="h5">{t('form.multiStepAddOrganization.confirmationView.title')}</Heading>
      <Spacer size={2} axis="vertical" />
      <Text>{t('form.multiStepAddOrganization.confirmationView.subtitleOne')}</Text>
      <Spacer size={4} axis="vertical" />
      <Text>
        {t('form.multiStepAddOrganization.confirmationView.subtitleTwo')}{' '}
        <span>
          <a href="https://poweredbypercent.zendesk.com/hc/en-us">
            {t('form.multiStepAddOrganization.confirmationView.helpCenter')}
          </a>
          .
        </span>
      </Text>
      <Spacer size={4} axis="vertical" />
    </div>
  )
}
