import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Icon } from '@percent/lemonade'

import styles from './Info.module.scss'
import { InfoProps } from './Info.types'

export const Info = ({ children }: InfoProps) => (
  <Alert
    severity="info"
    variant="outlined"
    icon={<Icon name="info" size={6} color="info400" className={styles.infoIcon} />}
    className={styles.infoWrapper}
  >
    <Typography className={styles.infoText} data-testid="info-text">
      {children}
    </Typography>
  </Alert>
)
