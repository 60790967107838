import React, { useMemo, useState } from 'react'

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs'
import { WizardProps } from './Wizard.types'
import { WizardContext } from './WizardContext/WizardContext'

export const Wizard = ({ steps, children }: WizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isAuthenticationComplete, setIsAuthenticationComplete] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const WizardContextValue = useMemo(() => {
    return {
      currentStep,
      setNextStep: handleNextStep,
      steps,
      isAuthenticationComplete,
      setIsAuthenticationComplete
    }
  }, [currentStep, handleNextStep, steps, isAuthenticationComplete, setIsAuthenticationComplete])

  return (
    <WizardContext.Provider value={WizardContextValue}>
      <Breadcrumbs />
      {!isAuthenticationComplete && steps[currentStep].step}
      {isAuthenticationComplete && children}
    </WizardContext.Provider>
  )
}
