import { Alert, Button, Checkbox, FileInput, FormField } from '@percent/lemonade'
import styles from './AgentVerificationDocuments.module.scss'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { AgentVerificationDocumentsProps } from './AgentVerificationDocuments.types'
import { useState } from 'react'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'

const ALLOWED_FILE_TYPES = '.jpg,.jpeg,.png,.pdf'
const MAX_FILES = 5
const MAX_FILE_SIZE_IN_MB = 5
const TERMS_URL = 'https://poweredbypercent.com/legal/#global-charity-terms-and-conditions'
const PRIVACY_POLICY_URL = 'https://poweredbypercent.com/legal/#privacy-policy'
export const ZENDESK_URL = 'https://poweredbypercent.zendesk.com/hc/en-us'

const bulletListKeys = ['depositSlip', 'governingDocuments', 'employmentConfirmation'] as const

const validateFileSize = (files?: File[] | null) =>
  files ? files?.filter(file => file?.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024)?.length === 0 : true

export const AgentVerificationDocuments = ({
  agentVerificationId,
  agentVerificationToken,
  onDocumentsSubmitted,
  organisationName
}: AgentVerificationDocumentsProps) => {
  const [hasError, setHasError] = useState(false)
  const { t } = useTranslation()
  const { agentVerificationService } = useServices()

  const [{ isLoading }, { apiFunc: submitDocuments }] = useMutation(
    agentVerificationService.postAgentVerificationDocuments,
    () => onDocumentsSubmitted(),
    () => setHasError(true)
  )

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      files: null,
      termsAndConditions: false
    },
    validationSchema: Yup.object().shape({
      files: Yup.array()
        .nullable()
        .min(1, '')
        .max(MAX_FILES, 'errorMessage.maxFilesLimit')
        .test('validate-file-size', 'errorMessage.maxFileSize', validateFileSize),
      termsAndConditions: Yup.bool().oneOf([true])
    }),
    onSubmit: async values => {
      if (values.files) {
        const files = values.files as File[]
        const formData = new FormData()

        files.forEach((file, index) => formData.append(`file${index}`, file))

        submitDocuments({
          agentVerificationId,
          agentVerificationToken,
          formData
        })
      }
    }
  })

  const { values, setFieldValue, isValid, errors, submitForm } = formik
  const uploadDocumentationText = t('agentVerificationDocuments.uploadDocumentation')

  const toggleTermsAndConditions = () => setFieldValue('termsAndConditions', !values.termsAndConditions)

  return (
    <FormikProvider value={formik}>
      <div className={styles.documentsContainer} data-testid="agent-verification-documents-form">
        <h5>{uploadDocumentationText}</h5>

        {hasError && (
          <Alert variant="error" title={t('agentVerificationDocuments.errorMessage.title')}>
            {t('agentVerificationDocuments.errorMessage.description')}{' '}
            <a href={ZENDESK_URL} target="_blank" rel="noreferrer">
              {t('agentVerificationDocuments.errorMessage.helpCenter')}
            </a>
          </Alert>
        )}
        <div>
          <p>{t('agentVerificationDocuments.helpUsVerify', { organisationName })}</p>
          <ul>
            {bulletListKeys.map(key => (
              <li key={key}>{t(`agentVerificationDocuments.bulletList.${key}`)}</li>
            ))}
          </ul>
        </div>

        <p>{t('agentVerificationDocuments.note')}</p>

        <div className={styles.fileInputWrapper}>
          <FormField
            label={uploadDocumentationText}
            necessity="required"
            status={errors.files ? 'danger' : 'default'}
            statusMessage={errors.files && t(errors.files as LocaleKey)}
          >
            <FileInput
              fileUploadLabel={t('typography.selectFiles')}
              placeholder={t('agentVerificationDocuments.fileInputPlaceholder', { filesNumber: MAX_FILES })}
              onChangeForMultiple={files => {
                setFieldValue('files', files)
              }}
              accept={ALLOWED_FILE_TYPES}
              allowMultiple
              dataTestId="documentation-files-button"
            />
          </FormField>
        </div>

        <div className={styles.termsAndConditionsWrapper}>
          <p>{t('agentVerificationDocuments.acceptTerms')}</p>
          <Checkbox
            active={values.termsAndConditions}
            variant="default"
            label={
              <span>
                {t('typography.agreeToThe')}{' '}
                <a href={TERMS_URL} target="_blank" rel="noreferrer">
                  {t('typography.terms')}
                </a>
                {' & '}
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  {t('typography.privacyPolicy')}
                </a>
              </span>
            }
            name="termsAndConditions"
            dataTestId="terms-and-conditions-checkbox"
            onChange={toggleTermsAndConditions}
          />
        </div>
        <Button
          onPress={submitForm}
          loading={isLoading}
          disabled={!values.files || !isValid || isLoading}
          data-testid="upload-documentation-button"
        >
          {uploadDocumentationText}
        </Button>
      </div>
    </FormikProvider>
  )
}
