import { Grid, Typography } from '@material-ui/core'

import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { AuthBackgroundImage } from '@percent/cause-dashboard/common/components'
import { useTranslation } from 'react-i18next'

import { CrossRedCircle } from '@percent/cause-dashboard/common/components/icons/CrossRedCircle'
import styles from './LargeErrorView.module.scss'
import { AnimatedContainer } from '@percent/lemonade'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'

export const LargeErrorView = ({ message }: { message: LocaleKey }) => {
  const { t } = useTranslation()

  return (
    <Grid
      spacing={0}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={styles.errorViewContainer}
    >
      <Grid item xs={12}>
        <div className={styles.errorContainer}>
          <div className={styles.error}>
            <AnimatedContainer>
              <div className={styles.errorIconContainer}>
                <CrossRedCircle />
              </div>
            </AnimatedContainer>
            <Typography variant="h5" color="secondary">
              {t('errorMessage.somethingWentWrong')}
            </Typography>
            <Typography>{t(message)}</Typography>
            <Typography>
              <a href="https://poweredbypercent.zendesk.com/" target="_blank" rel="noreferrer">
                poweredbypercent.zendesk.com
              </a>
            </Typography>
          </div>
        </div>
      </Grid>
      <AuthBackgroundImage />
      <Footer />
    </Grid>
  )
}
