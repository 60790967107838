import React, { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Typography } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'

import { LocationState } from '../TwoFactorAuthentication.types'

import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { FieldError, Loader } from '@percent/cause-dashboard/common/components'
import styles from './LoginWithRecoveryCode.module.scss'
import { LoginWithConfirmationCodeProps } from './LoginWithRecoveryCode.types'
import { SaveBackupCode } from './SaveBackupCodeStep/SaveBackupCode'
import { AuthView } from '@percent/cause-dashboard/app/auth/auth/AuthView'
import { recoveryCodeRegExp } from '@percent/cause-dashboard/common/utility/validation'

export const LoginWithRecoveryCode = ({
  errorMessage,
  isLoading,
  loginFunc,
  isRecoveryCodeConfirmed,
  userData,
  setErrorMessage
}: LoginWithConfirmationCodeProps) => {
  const { state } = useLocation<LocationState>()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      backupCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        backupCode: yup
          .string()
          .required('Required')
          .matches(recoveryCodeRegExp, t('errorMessage.invalidRecoveryCodeFormat'))
      }),
    onSubmit: ({ backupCode }: { backupCode: string }) => {
      loginFunc({
        backupCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.backupCode) {
      setErrorMessage('')
    }
  }, [values.backupCode, setErrorMessage])

  if (isLoading) {
    return <Loader />
  }

  return isRecoveryCodeConfirmed ? (
    <SaveBackupCode otpCode={userData?.twoFaBackupCode} userData={userData} />
  ) : (
    <AuthView heading="Two-step verification" handleSubmit={handleSubmit}>
      <Typography className={styles.passwordText} variant="body1">
        {t('form.enterRecoveryCode')}
      </Typography>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.enterRecoveryCodeLabel')}
          status={touched.backupCode && errors.backupCode ? 'danger' : 'default'}
          statusMessage={errors.backupCode}
          data-testid="backupCode"
        >
          <TextInput
            name="backupCode"
            placeholder={t('form.placeholderRecoveryCode')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.backupCode}
          />
        </FormField>
      </FormikProvider>
      {errorMessage && <FieldError error={errorMessage} />}
      <Spacer size={5} axis="vertical" />
      <Button stretch size="large" data-testid="auth-active-button" type="submit">
        {t('button.confirm')}
      </Button>
      <Typography variant="body2" className={styles.loginFooterText}>
        <span>
          <Link
            to={{
              pathname: '/signin/2fa',
              state: { ...state }
            }}
          >
            {t('2fa.useTwoFACode')}
          </Link>
        </span>
      </Typography>
      <Typography variant="body2">
        <span>
          <Link to="/signin">{t('2fa.loginDifferentUser')}</Link>
        </span>
      </Typography>
    </AuthView>
  )
}
