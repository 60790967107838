import { Grid, TableCell, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import {
  formatAmount,
  formatMinorUnitsValueToFullNumberWithDecimals
} from '@percent/cause-dashboard/common/utility/money/formatAmount'
import { useTranslation } from 'react-i18next'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import { useAuthState, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { Payout } from '@percent/cause-dashboard/services/payouts/payoutsService.types'
import { dayJS } from '@percent/cause-dashboard/common/utility/date'
import EmptyTableImage from '@percent/cause-dashboard/common/assets/images/no-payouts.png'
import styles from './PayoutsPage.module.scss'
import { Badge } from 'libs/shared/ui-lemonade/src/components/badge'
import { Icon } from '@percent/lemonade'

const columns = [
  { id: 'typography.payoutsTableAmount', isSortable: false, props: { width: '14%' } },
  { id: 'typography.payoutsTableStatus', isSortable: false, props: { width: 'auto' } },
  { id: 'typography.payoutsTablePayoutDate', isSortable: false, props: { width: '23%' } }
]

export function PayoutsPage() {
  const { t } = useTranslation()
  const { payoutsService } = useServices()
  const {
    currencyInfo,
    authState: { user }
  } = useAuthState()
  const { push } = useHistory()

  const [{ data, totalResults, isLoading, errorMessage }, { nextPage, previousPage }] = useQueryList(
    payoutsService.getPayoutsList(user?.organisationId as string)
  )

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <Grid item>
        <Table
          data={data}
          isLoading={false}
          totalResults={totalResults}
          previousPage={previousPage}
          nextPage={nextPage}
          columns={columns}
          emptyTableText={t('typography.noPayouts')}
          emptyTableImage={EmptyTableImage}
          orderBy=""
        >
          {data?.map(({ id, paidAt, paidAmount, paidCurrencyCode }: Payout, index: number) => (
            <TableRow key={`donation-list-${id}`} className={styles.listItem}>
              <TableCell align="left">
                {paidAmount
                  ? formatAmount({
                      currencyCode: paidCurrencyCode,
                      value: formatMinorUnitsValueToFullNumberWithDecimals(
                        paidAmount,
                        currencyInfo?.find(el => el.code === paidCurrencyCode)?.minorUnits as number
                      )
                    })
                  : null}
              </TableCell>
              <TableCell align="left">
                <Badge variant="positive" icon="approve">
                  {t('typography.paid')}
                </Badge>
              </TableCell>
              <TableCell>{dayJS(paidAt).format('DD MMM YYYY')}</TableCell>
              <TableCell
                align="right"
                className={styles.chevronLink}
                onClick={() => {
                  push(`/payouts/${id}`)
                }}
              >
                <Icon name="chevron-right" size={4} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
    </Grid>
  )
}
