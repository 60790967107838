import React from 'react'
import { Grid } from '@material-ui/core'

import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { detailTitle, DETAIL_BULLET_POINTS_DEFAULT } from '@percent/cause-dashboard/constants/emailVerificationData'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import styles from './EmailVerificationPage.module.scss'
import { useToast } from '@percent/cause-dashboard/containers/ToastContext'
import { EmailVerificationDetail } from './EmailVerificationDetail'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'

export function EmailVerificationPage() {
  const { t } = useTranslation()
  const toast = useToast()
  const { authService } = useServices()
  const [{ isLoading, errorMessage, success }, { apiFunc: verifyAccountRequest }] = useMutation(
    authService.verifyAccountRequest
  )
  const handleEmailSubmit = () => {
    verifyAccountRequest(undefined)
    toast('Email resent', 'success')
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item sm={12} md={6} className={styles.emailVerificationSection}>
        <EmailVerificationDetail handleResendEmailSubmit={handleEmailSubmit} loading={isLoading} success={success} />
      </Grid>
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={DETAIL_BULLET_POINTS_DEFAULT} />
      </Grid>
    </Grid>
  )
}
