import React from 'react'
import { FormControl, Input, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { FieldMeta } from '../fieldMeta/FieldMeta'

import styles from './FieldInput.module.scss'
import { FieldInputProps } from './FieldInput.types'

export function FieldInput({
  value,
  label,
  error,
  onChange,
  placeHolder,
  handleBlur,
  name,
  disabled,
  addonLabel,
  addonLabelText,
  addonLabelLink,
  type,
  touched,
  countLimit,
  errorMessage
}: FieldInputProps) {
  const errorMsg = touched && error ? error : undefined
  const charCount = value ? `${value}`.length : 0

  return (
    <FormControl className={styles.field}>
      <label className={styles.fieldLabel} htmlFor={name}>
        {label}
        <span>
          {addonLabel && addonLabelLink && (
            <Link to={addonLabelLink}>
              <Typography className={styles.addonLabelClassname}>{addonLabelText}</Typography>
            </Link>
          )}
        </span>
      </label>
      <Input
        id={label}
        type={type || 'text'}
        disabled={disabled}
        name={name}
        value={value}
        placeholder={placeHolder}
        onBlur={handleBlur}
        onChange={e => onChange(e.target.value)}
        inputProps={{
          'data-testid': name,
          className: (errorMsg || errorMessage) && styles.fieldInput
        }}
        disableUnderline
      />
      <FieldMeta characterCount={countLimit ? { charCount, countLimit } : undefined} error={errorMsg} />
    </FormControl>
  )
}
