import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import { DeleteItemModal } from '../modals/deleteItem/DeleteItemModal'
import { DeleteItemSuccessModal } from '../modals/deleteItemSuccess/DeleteItemSuccessModal'
import { EditItemModal } from '../modals/editItem/EditItemModal'

import { Button } from '@percent/lemonade'
import { MenuListComposition } from '@percent/cause-dashboard/common/components/menuListComposition/MenuListComposition'
import { PaginationActions } from '@percent/cause-dashboard/common/components/PaginationActions'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import styles from './MilestonesTable.module.scss'
import { useTranslation } from 'react-i18next'
import { MilestonesTableProps } from './MilestonesTable.types'
import { NoResultTable } from '@percent/cause-dashboard/common/components/table/noResultTable/NoResultTable'
import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { MilestonesItem } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { formatMoney } from '@percent/cause-dashboard/common/utility/utility'
import { Info } from '@percent/cause-dashboard/common/components/info'
import EmptyTableImage from '@percent/cause-dashboard/common/assets/images/no-shopping-items.png'

export function MilestonesTable({
  data,
  isLoading,
  errorMessage,
  totalResults,
  addItem,
  editSave,
  refresh,
  nextPage,
  previousPage,
  noMilestoneInLocation,
  currencyCode
}: MilestonesTableProps) {
  const { t } = useTranslation()
  const [editItem, setEditItem] = useState<MilestonesItem | undefined>(undefined)
  const [openEditItemModal, setOpenEditItemModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false)
  const [shoppingListItemId, setShoppingListItemId] = useState({
    sId: '',
    reward: ''
  })

  const handleAddItem = () => {
    addItem()
  }

  const handleEditItemOpen = (id?: string) => () => {
    setOpenEditItemModal(true)
    setEditItem(data?.find((item: MilestonesItem) => item.id === id))
  }

  const handleEditItemClose = () => setOpenEditItemModal(false)

  const handleEditItemSave = (success: boolean) => {
    editSave(success)
    handleEditItemClose()
  }

  const handleOpenDeleteModal = () => setOpenDeleteModal(true)

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleCloseDeleteSuccessModal = () => setOpenDeleteSuccessModal(false)

  const handleOpenDeleteSuccessModal = () => setOpenDeleteSuccessModal(true)

  const rowShoppingListItemClicked = (sId: string, reward: string) => {
    setShoppingListItemId({
      sId,
      reward
    })
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid item>
      <TableContainer component={Paper}>
        <ModalContainer
          openModal={openEditItemModal}
          onClose={handleEditItemClose}
          headerTitle={t('dialog.editMilestone.title')}
        >
          <EditItemModal
            itemId={editItem ? editItem.id : ''}
            itemAmount={editItem ? editItem.amount : 0}
            itemReward={editItem?.reward || ''}
            cancel={handleEditItemClose}
            save={handleEditItemSave}
          />
        </ModalContainer>
        <DeleteItemModal
          onClose={handleCloseDeleteModal}
          open={openDeleteModal}
          id={shoppingListItemId}
          refresh={refresh}
          handleOpenDeleteSuccessModal={handleOpenDeleteSuccessModal}
        />
        <DeleteItemSuccessModal
          openDeleteSuccessModal={openDeleteSuccessModal}
          handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
        />
        <Toolbar className={styles.toolBar}>
          <Grid container justifyContent="space-between">
            <Typography variant="h6">{t('menu.milestones')}</Typography>
          </Grid>
          {!noMilestoneInLocation && (
            <Grid item>
              <Button data-testid="container-add-item-button" onPress={handleAddItem}>
                {t('button.addMilestone')}
              </Button>
            </Grid>
          )}
        </Toolbar>
        <Info>
          {t('typography.milestonesInfoOne')}
          {getSymbolFromCurrency(currencyCode || 'USD')}
          {t('typography.milestonesInfoTwo')}
        </Info>
        {noMilestoneInLocation && (
          <NoResultTable image={EmptyTableImage} text={t('typography.noMilestonesInLocation')} />
        )}
        {totalResults === 0 && !noMilestoneInLocation && (
          <NoResultTable
            image={EmptyTableImage}
            text={t('typography.noMilestones')}
            actionsRow={
              <Button data-testid="add-shopping-list" type="submit" onPress={handleAddItem}>
                {t('button.addMilestone')}
              </Button>
            }
          />
        )}{' '}
        {(totalResults && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCellAmount}>{t('table.header.amount')}</TableCell>
                <TableCell>{t('table.header.canBeAchieved')}</TableCell>
                <TableCell className={styles.tableCellMenu} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(({ amount, reward, currencyCode, id }: MilestonesItem) => (
                <TableRow key={`donation-list-${id}`}>
                  <TableCell>{`${getSymbolFromCurrency(currencyCode)}${formatMoney(amount)}`}</TableCell>
                  <TableCell className={styles.reward}>{reward}</TableCell>
                  <TableCell
                    className={styles.ellipsesCell}
                    onClick={() => rowShoppingListItemClicked(id, reward)}
                    data-testid={`ellipses-${id}`}
                  >
                    <MenuListComposition
                      menuItemName={[
                        {
                          title: 'Edit',
                          openModal: handleEditItemOpen(id)
                        },
                        {
                          title: 'Delete',
                          openModal: handleOpenDeleteModal
                        }
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="footer" colSpan={5}>
                  <PaginationActions
                    count={totalResults}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    refresh={refresh}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )) ||
          ''}
      </TableContainer>
    </Grid>
  )
}
