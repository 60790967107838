import {
  AgentVerificationConfirmation,
  AgentVerificationServiceCapabilities,
  AgentVerificationAssociation,
  PostAgentVerificationDocumentsParams,
  AgentVerificationDocumentStatusResponse
} from './AgentVerification.types'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'

export const agentVerificationService = ({ percentClient }: AgentVerificationServiceCapabilities) => ({
  getConfirmAgentVerificationRequest: (agentVerificationToken?: string) =>
    percentClient.get<PBPResponse<AgentVerificationConfirmation>>(
      `v1/agent-verification-user-verification?agentVerificationToken=${agentVerificationToken}`
    ),
  postAgentVerificationAssociation: ({
    verificationToken,
    isAssociatedWithOrganisation
  }: {
    verificationToken: string
    isAssociatedWithOrganisation: boolean
  }) =>
    percentClient.post<PBPResponse<AgentVerificationAssociation>>(`v1/agent-verifications/organisation-association`, {
      verificationToken,
      isAssociatedWithOrganisation
    }),
  postAgentVerificationDocuments: ({
    agentVerificationId,
    agentVerificationToken,
    formData
  }: PostAgentVerificationDocumentsParams) =>
    percentClient.post(
      `v1/agent-verifications/${agentVerificationId}/documents?agentVerificationToken=${agentVerificationToken}`,
      formData
    ),
  getAgentVerificationDocumentStatus: (agentVerificationToken?: string) =>
    percentClient.get<AgentVerificationDocumentStatusResponse>(
      `v1/agent-verifications/document-status?agentVerificationToken=${agentVerificationToken}`
    )
})
