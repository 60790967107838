import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { Loader } from '../Loader'

import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { selectAuthState, AuthState } from '@percent/cause-dashboard/context/auth'
import { useFeatureFlag } from '../../hooks/useFeatureFlag/useFeatureFlag'
import { Feature } from '@percent/cause-dashboard/app/providers/SplitSdkProvider'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { useLocation } from 'react-router-dom'

export enum UserState {
  LoggedOut = 'LoggedOut',
  LoggedInUnverified = 'LoggedInUnverified',
  LoggedIn = 'LoggedIn',
  LoggedInClaimMade = 'LoggedInClaimMade',
  Any = 'Any'
}

export const MEMORIZED_PATHNAME_KEY = 'MEMORIZED_PATHNAME'
export const MEMORIZED_PATHNAME_AT_KEY = 'MEMORIZED_PATHNAME_AT_KEY'

export const getRedirectionRouteForState = ({
  authState: { sessionExpired, userVerified, loggedIn, claimMade },
  memorizePathBeforeRedirect
}: {
  authState: AuthState
  memorizePathBeforeRedirect: boolean
}) => {
  if (sessionExpired) {
    return RoutePath.EXPIRED_SESSION
  }

  if (!userVerified && loggedIn) {
    return RoutePath.EMAIL_VERIFICATION
  }

  if (userVerified && loggedIn && claimMade) {
    return RoutePath.PROFILE
  }

  if (memorizePathBeforeRedirect) {
    return RoutePath.SIGNIN
  }

  return RoutePath.CLAIM
}

export const mapAuthStateToUserState = ({ loggedIn, userVerified, claimMade }: AuthState) => {
  if (loggedIn && userVerified && claimMade) {
    return UserState.LoggedInClaimMade
  } else if (loggedIn && !userVerified) {
    return UserState.LoggedInUnverified
  } else if (loggedIn && userVerified) {
    return UserState.LoggedIn
  } else {
    return UserState.LoggedOut
  }
}

const canAccessRoute = (authState: AuthState, allowedStates: UserState[], disallowedStatuses: UserState[]) => {
  if (allowedStates.includes(UserState.Any)) return true

  const userState = mapAuthStateToUserState(authState)

  if (disallowedStatuses.some(disallowedStatuses => disallowedStatuses === userState)) {
    return false
  }
  return allowedStates.some(allowedState => allowedState === userState)
}

export type AuthRouteProps = RouteProps & {
  component?: React.ComponentType<any>
  allow: UserState[]
  disallow?: UserState[]
  feature?: Feature
  memorizePathBeforeRedirect?: boolean
}

export const AuthRoute = ({
  allow,
  disallow,
  feature,
  component: Component,
  children: Children,
  memorizePathBeforeRedirect = false,
  ...rest
}: AuthRouteProps) => {
  const {
    authState: appState,
    authState: { user, isAuthorised }
  } = useAuthState()
  const authState = selectAuthState(appState)
  const featureFlags = useFeatureFlag()
  const location = useLocation()

  useEffect(() => {
    if (memorizePathBeforeRedirect) {
      const path = `${location.pathname}${location.search}`
      localStorage.setItem(MEMORIZED_PATHNAME_KEY, path)
      localStorage.setItem(MEMORIZED_PATHNAME_AT_KEY, new Date().toISOString())
    }
  }, [location, memorizePathBeforeRedirect])

  if (isAuthorised && !user) {
    return <Loader />
  }

  const allowFeature = !feature || featureFlags[feature]

  return (
    <Route
      render={props => {
        if (allowFeature && canAccessRoute(authState, allow, disallow || [])) {
          if (Component) {
            return (<Component {...rest} {...props} />) as any
          }

          return Children
        }

        return <Redirect to={getRedirectionRouteForState({ authState, memorizePathBeforeRedirect })} />
      }}
    />
  )
}
