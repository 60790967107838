import { LocaleKey } from '../i18n/config'

export const detailTitle = 'emailVerification.detailTitle'

type BulletPointType = { title: LocaleKey }

export const DETAIL_BULLET_POINTS_DEFAULT: BulletPointType[] = [
  {
    title: 'emailVerification.bulletPoint.donors'
  },
  {
    title: 'emailVerification.bulletPoint.donations'
  },
  {
    title: 'emailVerification.bulletPoint.discount'
  },
  {
    title: 'emailVerification.bulletPoint.grow'
  },
  {
    title: 'emailVerification.bulletPoint.access'
  },
  {
    title: 'emailVerification.bulletPoint.receive'
  },
  {
    title: 'emailVerification.bulletPoint.register'
  }
]

export const DETAIL_BULLET_POINTS_STATUS_CHECK: BulletPointType[] = [
  {
    title: 'emailVerification.bulletPoint.status'
  },
  {
    title: 'emailVerification.bulletPoint.donors'
  },
  {
    title: 'emailVerification.bulletPoint.donations'
  },
  {
    title: 'emailVerification.bulletPoint.grow'
  },
  {
    title: 'emailVerification.bulletPoint.access'
  },
  {
    title: 'emailVerification.bulletPoint.receive'
  },
  {
    title: 'emailVerification.bulletPoint.register'
  }
]

export const VERIFICATION_DATA = {
  backButtonTitle: 'emailVerification.verificationData.backButtonTitle',
  mainButtonTitle: 'emailVerification.verificationData.mainButtonTitle',
  title: 'emailVerification.verificationData.title'
} as const
