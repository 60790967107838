import React from 'react'

import { useWizard } from '../Wizard/useWizard/useWizard'

import { Button, Spacer, Copy } from '@percent/lemonade'
import { useDownloadStringFile } from '@percent/cause-dashboard/common/hooks/useDownloadStringFile/useDownloadStringFile'
import { ReactComponent as Download } from '@percent/cause-dashboard/common/assets/images/download.svg'
import styles from './SaveBackupCodeStep.module.scss'
import { useTranslation } from 'react-i18next'
import { SaveBackupCodeStepProps } from './SaveBackupCodeStep.types'

export function SaveBackupCodeStep({ otpCode = '' }: SaveBackupCodeStepProps) {
  const { t } = useTranslation()
  const { setIsAuthenticationComplete } = useWizard()
  const downloadCode = useDownloadStringFile(otpCode, 'otp-backup.txt')

  const handleClick = () => setIsAuthenticationComplete(true)

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.header}>{t('2fa.saveBackupCode')}</h1>
        <p className={styles.subtext}>{t('2fa.saveBackupCodeSubtext')}</p>
        <Button disabled>{otpCode}</Button>
        <Spacer size={2} />
        <div className={styles.actionWrapper}>
          <div className={styles.copyWrapper}>
            <Copy text={otpCode} />
            <p className={styles.actionText}>{t('typography.copy')}</p>
          </div>
          <div className={styles.downloadWrapper}>
            <Download className={styles.download} onClick={downloadCode} />
            <p className={styles.actionText}>{t('typography.download')}</p>
          </div>
        </div>
        <div className={styles.separator} />
        <Button onPress={handleClick} type="button">
          {t('button.done')}
        </Button>
      </div>
    </div>
  )
}
