import React from 'react'

import { ReactComponent as InnerRedCircle } from '@percent/cause-dashboard/common/assets/images/modal/inner-red-circle.svg'
import styles from './CrossRedCircle.module.scss'
import { ReactComponent as Cross } from '@percent/cause-dashboard/common/assets/images/modal/cross.svg'
import { ReactComponent as OuterRedCircle } from '@percent/cause-dashboard/common/assets/images/modal/outer-red-circle.svg'
import { Grid } from '@material-ui/core'

export function CrossRedCircle() {
  return (
    <Grid alignItems="center" direction="column" className={styles.container}>
      <OuterRedCircle className={styles.outerRedCircle} />
      <InnerRedCircle className={styles.innerRedCircle} />
      <Cross className={styles.cross} />
    </Grid>
  )
}
