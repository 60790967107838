import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'

import { Loader } from '../../../common/components/Loader'
import { FieldError } from '../../../common/components/field/fieldError/FieldError'

import styles from './ImageUploader.module.scss'
import defaultLogo from '@percent/cause-dashboard/common/assets/images/default-logo.png'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Avatar } from '@percent/lemonade'
import { Box } from '@mui/material'
import { ImageUploaderProps } from './ImageUploader.types'

export function ImageUploader({
  organisationName,
  organisationId,
  currentImage,
  setFieldValue,
  error,
  disableInput,
  updatedImage,
  setUpdatedImage,
  setFieldError
}: ImageUploaderProps) {
  const { t } = useTranslation()
  const [currentImageUrl, setCurrentImageUrl] = useState<string | undefined>(undefined)
  const {
    causeService: { postOrganisationLogo }
  } = useServices()
  const [{ data, isLoading, errorMessage }, { apiFunc }] = useMutation(postOrganisationLogo)

  const handleImageChange = (imageFile: any) => {
    const imageData = imageFile[0]
    const formData = new FormData()
    formData.append('file', imageData)
    apiFunc({
      payload: {
        formData
      },
      organisationId
    })
  }

  useEffect(() => {
    if (data) {
      setUpdatedImage(data.url)
      setFieldValue('logo', data.url)
    }

    if (errorMessage) {
      setFieldError('logo', errorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, errorMessage])

  useEffect(() => {
    setCurrentImageUrl(currentImage ? `${currentImage}` : undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box display="flex">
      {updatedImage || currentImageUrl ? (
        <Avatar image={updatedImage || currentImageUrl} alt={organisationName || ''} size="large" />
      ) : (
        <Avatar image={organisationName ? '' : defaultLogo} alt={organisationName || ''} size="large" />
      )}

      <Box ml={3}>
        <Typography className={styles.title}>{t('typography.uploadCauseLogo')}</Typography>
        <Typography className={styles.requirementInfo}>{t('typography.pngJpegFiles')}</Typography>
        {!disableInput && (
          <div>
            <input
              name="logo"
              id="logo"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleImageChange(e.target.files)
              }}
              data-testid="image"
              accept="image/x-png,image/jpeg,image/png"
              style={{ display: 'none' }}
            />
            <label htmlFor="logo" className={styles.changeImageButton}>
              {t('form.chooseImage')}
            </label>
            {error && <FieldError error={error} />}
          </div>
        )}
      </Box>
    </Box>
  )
}
