import { Typography } from '@material-ui/core'
import { VerificationNotificationTextProps } from './NotificationBarText.types'
import styles from './NotificationBar.module.scss'

export const VerificationNotificationText = ({
  notificationText,
  buttonText,
  buttonUrl,
  onButtonClick,
  showApplicationStatus
}: VerificationNotificationTextProps) => {
  return (
    <Typography>
      {notificationText}{' '}
      {buttonText &&
        (showApplicationStatus ? (
          <button className={styles.openModal} data-testid="view-status-button" onClick={onButtonClick}>
            {buttonText}
          </button>
        ) : (
          <a className={styles.link} data-testid="contact-us-link" href={buttonUrl} target="_blank" rel="noreferrer">
            {buttonText}.
          </a>
        ))}
    </Typography>
  )
}
