import React, { useEffect, useState } from 'react'
import { FormControl, MenuItem, Select } from '@material-ui/core'

import styles from './FieldDropDown.module.scss'
import { FieldDropDownProps } from './FieldDropDown.types'

export function FieldDropDown({ initialValue, onClick, selectTitle, valueArray }: FieldDropDownProps) {
  const [selectValue, setSelectValue] = useState(initialValue)

  useEffect(() => {
    setSelectValue(initialValue)
  }, [initialValue])

  const handleChange = (event: any) => {
    setSelectValue(event.target.value)

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <FormControl variant="outlined" className={styles.labelContainer}>
      {selectTitle && <label className={styles.label}>{selectTitle}</label>}
      <Select
        className={styles.select}
        inputProps={{ 'data-testid': 'select' }}
        value={selectValue}
        onChange={handleChange}
        color="secondary"
      >
        {valueArray?.map(({ title, value }) => (
          <MenuItem key={title} value={value} className={styles.menuItem}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
