import { Container, Button } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './NotificationBar.module.scss'
import { ZENDESK_TICKET_LINK } from '@percent/cause-dashboard/constants/zendesk'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { VerificationNotificationText } from './NotificationBarText'
import { useFeatureFlag } from '../../hooks/useFeatureFlag/useFeatureFlag'
import { ReactComponent as CloseIcon } from '@percent/cause-dashboard/common/assets/images/close-icon-white.svg'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'
import { NotificationBarProps } from './NotificationBar.types'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { PartnerApplicationModalContainer } from '../modal/PartnerApplicationModal/PartnerApplicationModalContainer'
import { useQuery } from '../../hooks/useQuery/useQuery'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Loader } from '@percent/cause-dashboard/common/components'
import { addHours, isAfter } from 'date-fns'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'

enum NotificationType {
  CLAIM_PENDING,
  CLAIM_REJECTED,
  APPLICATION_STATUS,
  BANK_REJECTED,
  DISMISSABLE_ANOUNCEMENT,
  NONE
}

export const dissmissedAnouncementKey = 'dissmissedAnouncement'

export function NotificationBar({ config }: NotificationBarProps) {
  const {
    authState: appState,
    authState: { claimOrganisation, user }
  } = useAuthState()
  const { storeService } = useServices()
  const [{ data: validationStatus, isLoading }] = useQuery(
    storeService.getValidationSubmission,
    user?.validationSubmissionSourceId ?? ''
  )
  const { rejectedBankDetails } = selectAuthState(appState)
  const { t } = useTranslation()
  const [notificationType, setNotificationType] = useState(NotificationType.NONE)
  const { sitewideMessage } = useFeatureFlag()
  const { setIsOpened } = useNotificationBar()
  const [isApplicationNotification, setIsApplicationNotification] = useState(false)
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false)

  const { track } = useCausesPortalAnalytics()
  const trackedOpen = useRef(false)

  const isRecentlyProcessed = (date: string): boolean => {
    const processedAtDate = new Date(date)
    const threshold = addHours(new Date(), -24)
    return isAfter(processedAtDate, threshold)
  }

  const validationData = validationStatus?.data?.data
  const partnerName = validationData?.programName

  useEffect(() => {
    if (user?.validationSubmissionSourceId && validationData?.submittedAt) {
      const isPending = validationData?.status === 'PENDING'
      const isProcessedRecently = validationData?.processedAt && isRecentlyProcessed(validationData?.processedAt)

      if (isPending || isProcessedRecently) {
        setIsApplicationNotification(true)
        setIsApplicationModalOpen(true)
      }
    }
  }, [validationData, user?.validationSubmissionSourceId])

  useEffect(() => {
    const checkNotificationType = () => {
      const dissmissedCurrentAnouncement =
        localStorage.getItem(dissmissedAnouncementKey) === config.strings.notificationBanner.textKey
      let type = NotificationType.NONE
      if (isApplicationNotification) {
        type = NotificationType.APPLICATION_STATUS
      } else if (claimOrganisation?.rejectedAt) {
        type = NotificationType.CLAIM_REJECTED
      } else if (!claimOrganisation?.acceptedAt) {
        type = NotificationType.CLAIM_PENDING
      } else if (rejectedBankDetails) {
        type = NotificationType.BANK_REJECTED
      } else if (!dissmissedCurrentAnouncement && sitewideMessage) {
        type = NotificationType.DISMISSABLE_ANOUNCEMENT
      }
      setNotificationType(type)
      setIsOpened(type !== NotificationType.NONE)
    }
    checkNotificationType()
  }, [
    claimOrganisation,
    rejectedBankDetails,
    sitewideMessage,
    isApplicationNotification,
    setNotificationType,
    setIsOpened,
    config
  ])

  useEffect(() => {
    if (isApplicationModalOpen && !trackedOpen.current) {
      void track('Partner Application Status Modal Shown', {
        partnerName,
        applicationStatus: validationData?.status
      })
      trackedOpen.current = true
    }
  }, [isApplicationModalOpen, track, partnerName, validationData?.status])

  useEffect(() => {
    if (!isApplicationModalOpen) {
      trackedOpen.current = false
    }
  }, [isApplicationModalOpen])

  const getNotificationText = (): LocaleKey => {
    switch (notificationType) {
      case NotificationType.APPLICATION_STATUS:
        return t('modalPartnerApplication.banner.text', { partnerName: `${partnerName}` }) as LocaleKey
      case NotificationType.CLAIM_PENDING:
        return 'typography.verificationNotification.pending'
      case NotificationType.CLAIM_REJECTED:
        return 'typography.verificationNotification.rejected'
      case NotificationType.BANK_REJECTED:
        return 'typography.rejectedBankDetailsNotification'
      default:
        return config.strings.notificationBanner.textKey as LocaleKey
    }
  }

  const getButtonText = (): LocaleKey => {
    switch (notificationType) {
      case NotificationType.APPLICATION_STATUS:
        return 'modalPartnerApplication.CTA.viewStatus'
      case NotificationType.CLAIM_PENDING:
      case NotificationType.CLAIM_REJECTED:
        return 'typography.verificationNotification.contactUs'
      case NotificationType.BANK_REJECTED:
        return 'typography.reachOutToUsLink'
      default:
        return config.strings.notificationBanner.buttonKey as LocaleKey
    }
  }

  const getButtonHref = () => {
    switch (notificationType) {
      case NotificationType.CLAIM_PENDING:
      case NotificationType.CLAIM_REJECTED:
        return ZENDESK_TICKET_LINK
      case NotificationType.BANK_REJECTED:
        return `${ZENDESK_TICKET_LINK}?ticket_form_id=14155148527633`
      default:
        return config.urls.notificationBannerLink
    }
  }

  const handleButtonClick = () => {
    if (notificationType === NotificationType.APPLICATION_STATUS) {
      setIsApplicationModalOpen(true)
    } else {
      getButtonHref()
    }
  }

  const dissmissNotification = () => {
    if (config.strings.notificationBanner.textKey) {
      localStorage.setItem(dissmissedAnouncementKey, config.strings.notificationBanner.textKey)
    }
    setNotificationType(NotificationType.NONE)
    setIsOpened(false)
  }

  if (notificationType === NotificationType.NONE) {
    return null
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Container
      className={
        notificationType === NotificationType.DISMISSABLE_ANOUNCEMENT
          ? styles.generalNotification
          : styles.verificationNotification
      }
    >
      <VerificationNotificationText
        notificationText={t(getNotificationText())}
        buttonText={t(getButtonText())}
        buttonUrl={getButtonHref() ?? ''}
        key={notificationType}
        onButtonClick={handleButtonClick}
        showApplicationStatus={notificationType === NotificationType.APPLICATION_STATUS}
      />
      {notificationType === NotificationType.DISMISSABLE_ANOUNCEMENT && (
        <Button className={styles.closeNotificationButton} onClick={() => dissmissNotification()}>
          <CloseIcon />
        </Button>
      )}
      {isApplicationModalOpen && (
        <>
          <div className={styles.backgroundBlur}></div>
          <PartnerApplicationModalContainer
            isModalOpen={isApplicationModalOpen}
            onClose={() => setIsApplicationModalOpen(false)}
            validationData={validationData}
          />
        </>
      )}
    </Container>
  )
}
