import { AxiosInstance } from 'axios'
import { AcceptInviteResponse } from './inviteService.types'

type InviteServiceCapabilities = {
  percentClient: AxiosInstance
}

export const inviteService = ({ percentClient }: InviteServiceCapabilities) => ({
  acceptInvite: ({ inviteId }: { inviteId: string }) =>
    percentClient.patch<AcceptInviteResponse>(`v1/iam/invite/${inviteId}/accept`),
  createAccount: ({ inviteId, password }: { inviteId: string; password: string }) =>
    percentClient.post(`v1/iam/invite/${inviteId}/create-account`, { password }),
  requestToJoinOrganisation: ({
    email,
    organisationId,
    token
  }: {
    email: string
    organisationId: string
    token?: string | null
  }) => percentClient.post(`v1/iam/invite/${organisationId}/request`, { email, token })
})
