import { Modal, FormField, Select, Button, Alert, useToast } from '@percent/lemonade'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'
import { ModalHeader } from '@percent/cause-dashboard/common/components/modal/Modal/ModalHeader'
import { ModalContent } from 'libs/shared/ui-lemonade/src/components/modal-content'
import { ModalFooter } from 'libs/shared/ui-lemonade/src/components/modal-footer/modal-footer'
import styles from './EditUserRoleModal.module.scss'
import { useEffect, useState } from 'react'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useTranslation } from 'react-i18next'

interface EditUserRoleModalProps {
  accountId: string
  role: {
    id: string
    displayName: string
  }
  email: string
  open: boolean
  close: VoidFunction
  onRoleChange: VoidFunction
}

export const EditUserRoleModal = ({ accountId, email, role, open, close, onRoleChange }: EditUserRoleModalProps) => {
  const { t } = useTranslation()
  const [isRoleSelected, setIsRoleSelected] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')

  const { iamService } = useServices()

  const [{ data }] = useQuery(iamService.getRoles, {})
  const rolesData = data?.data?.data?.roles
  const roles =
    rolesData
      ?.map(role => {
        return { label: role.displayName, value: role.id, description: role.description }
      })
      .filter(proposedRole => proposedRole.value !== role.id) || []

  const toast = useToast()
  const [
    { isLoading: isMutating, error, errorMessage },
    { apiFunc: updateUserRoleAPIRequest, setError, setErrorMessage }
  ] = useMutation(
    iamService.patchRoles,
    _success => {
      setIsRoleSelected(true)
      onRoleChange()
      close()
      toast.addToast(
        t('typography.userManagement.successAlert', { email: email, role: `'${selectedRole}'` }),
        'success'
      )
    },
    _error => {
      const customErrorMessage = t('typography.userManagement.errorAlert', { email: email, role: `'${selectedRole}'` })
      setErrorMessage(customErrorMessage)
    }
  )

  const roleId = role.id

  const formik = useFormik({
    initialValues: {
      roleId: roleId
    },
    validationSchema: () =>
      object().shape({
        roleId: string().required('Required')
      }),
    onSubmit: values => {
      updateUserRoleAPIRequest({
        accountId: accountId,
        roleId: values.roleId
      })
    }
  })

  const { touched, errors, handleSubmit, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (!open) {
      setIsRoleSelected(false)
      resetForm()
      setErrorMessage('')
      setError(null)
      setSelectedRole('')
    }
  }, [resetForm, setError, setErrorMessage, open])

  return (
    <Modal open={open} onClose={close}>
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <ModalHeader onClose={close} headerTitle="Edit role" />
          <ModalContent>
            {error && (
              <div className={styles.errorAlert}>
                <Alert variant={'error'}>{errorMessage}</Alert>
              </div>
            )}
            <span className={styles.boldText} data-testid={'email'}>
              {email}
            </span>
            {t('typography.userManagement.editUserRoleModalTitle', { role: `'${role.displayName}'` })}
            <div className={styles.editUserRoleModalFormFields} data-testid={'edit-role'}>
              <FormField
                label={t('typography.userManagement.role')}
                status={touched.roleId && errors.roleId ? 'danger' : 'default'}
                statusMessage={errors.roleId}
              >
                <Select
                  name="roleId"
                  data-testid="role-select"
                  placeholder={role.displayName}
                  onChange={selection => {
                    setFieldValue('roleId', selection.value)
                    setIsRoleSelected(true)
                    setSelectedRole(selection.label)
                  }}
                  options={roles}
                />
              </FormField>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button data-testid={'button-cancel'} type="button" size="large" variant="secondary" onPress={close}>
              {t('button.cancel')}
            </Button>
            <Button
              data-testid={'button-save-changes'}
              type="submit"
              size="large"
              variant="primary"
              disabled={!isRoleSelected}
              loading={isMutating}
            >
              {t('button.saveChanges')}
            </Button>
          </ModalFooter>
        </FormikProvider>
      </form>
    </Modal>
  )
}
