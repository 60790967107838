import { Grid, TableCell, TableRow, Typography } from '@material-ui/core'

import { Badge, Button } from '@percent/lemonade'
import { PressEvent } from '@react-types/shared'
import { useAuthState, useDidMountEffect, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import { Info } from '@percent/cause-dashboard/common/components/info'
import EmptyTableImage from '@percent/cause-dashboard/common/assets/images/no-donations.png'
import EmptyDateRangeTableImage from '@percent/cause-dashboard/common/assets/images/no-donations-in-date-range.png'
import { Donation } from '@percent/cause-dashboard/services/donations/donationsService.types'
import { dayJS, getFormattedDate } from '@percent/cause-dashboard/common/utility/date'
import {
  formatAmount,
  formatMinorUnitsValueToFullNumberWithDecimals
} from '@percent/cause-dashboard/common/utility/money/formatAmount'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import styles from './DonationsPage.module.scss'
import { useCallback, useRef, useState } from 'react'
import { useCSVDownloader } from '@percent/cause-dashboard/common/hooks/useCSVDownloader/useCSVDownloader'
import { DateRangePopper } from '@percent/cause-dashboard/common/components/dateRange/DateRangePopper/DateRangePopper'
import { csvFileName } from '@percent/cause-dashboard/common/utility/csvFileName'

export const DonationsPage = () => {
  const { t } = useTranslation()
  const {
    donationsService,
    reportingService: { getDonationReport }
  } = useServices()
  const {
    currencyInfo,
    authState: { user }
  } = useAuthState()
  const anchorEl = useRef<HTMLElement | null>(null)
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [queryParams, setQueryParams] = useQueryParams({
    pageSize: withDefault(NumberParam, 25),
    startDate: StringParam,
    endDate: StringParam
  })

  const formatDateParams = <T extends string | null | undefined>(startDate: T, endDate: T) => {
    const isEndDateInTheFuture = endDate ? dayJS(endDate).isAfter(dayJS()) : false

    return {
      startDate: startDate ? dayJS(queryParams.startDate).toISOString() : undefined,
      endDate: endDate && !isEndDateInTheFuture ? dayJS(endDate).toISOString() : dayJS().toISOString()
    } as { startDate: T; endDate: T }
  }

  const [{ data, totalResults, isLoading, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    donationsService.getDonationsList(user?.organisationId as string),
    {
      ...queryParams,
      ...formatDateParams(queryParams.startDate, queryParams.endDate)
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  const [{ loading: csvDownloaderLoading, errorCSVMessage }, { fetchDonationCSV }] = useCSVDownloader({
    service: getDonationReport(user?.organisationId as string),
    fileName: csvFileName,
    successMessage: t('toast.donationsListExportSuccess')
  })

  const handleClick = (event: PressEvent) => {
    anchorEl.current = event.target as HTMLElement
    setIsDatePickerOpened(!isDatePickerOpened)
  }

  const handleExport = () => {
    if (fetchDonationCSV) {
      fetchDonationCSV(formatDateParams(queryParams.startDate, queryParams.endDate))
    }
  }

  const handleClearDate = useCallback(() => {
    setQueryParams({ startDate: undefined, endDate: undefined })
  }, [setQueryParams])

  const columns = [
    { id: 'typography.donationsTableDonation', isSortable: false, props: { width: '16%' } },
    { id: 'typography.donationsTableDonorName', isSortable: false, props: { width: '16%' } },
    { id: 'typography.donationsTableDonorEmail', isSortable: false, props: { width: '24%' } },
    { id: 'typography.donationsTableMarketingConsent', isSortable: false, props: { width: '18%' } },
    { id: 'typography.donationsTablePartner', isSortable: false, props: { width: '14%' } },
    { id: 'typography.donationsTableDonationCreated', isSortable: false, props: { align: 'right' } }
  ]

  const isDateRangeSelected = queryParams.startDate && queryParams.endDate

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage || !!errorCSVMessage) {
    return <ErrorView errorMessage={errorMessage || (errorCSVMessage as string)} />
  }

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      {totalResults ? (
        <Grid item>
          <Info>{t('typography.donationsInfo')}</Info>
        </Grid>
      ) : null}

      <Grid item>
        <Table
          data={data}
          isLoading={false}
          totalResults={totalResults}
          previousPage={previousPage}
          nextPage={nextPage}
          columns={columns}
          emptyTableText={t(`typography.noDonations${isDateRangeSelected ? 'InDateRange' : ''}`)}
          emptyTableImage={isDateRangeSelected ? EmptyDateRangeTableImage : EmptyTableImage}
          orderBy=""
          filtersContent={
            (data?.length !== 0 || !!queryParams.startDate) && (
              <div className={styles.actionWrapper}>
                <Button
                  size="small"
                  type="submit"
                  variant={queryParams.startDate ? 'primary' : 'secondary'}
                  data-testid="show-date-range"
                  endIcon="chevron-down"
                  onPress={(e: PressEvent) => handleClick(e)}
                >
                  {t('typography.date')}{' '}
                  {(isDateRangeSelected &&
                    `${dayJS(queryParams.startDate).format('ll')} - ${dayJS(queryParams.endDate).format('ll')}`) ||
                    t('typography.all')}
                </Button>
                {isDateRangeSelected && (
                  <Typography className={styles.clearText} onClick={handleClearDate}>
                    {t('typography.clear')}
                  </Typography>
                )}
                <div className={styles.separator} />
                {csvDownloaderLoading ? (
                  <Button size="small" variant="secondary" data-testid="export-csv-loading">
                    {t('button.exporting')}
                  </Button>
                ) : (
                  <Button size="small" variant="secondary" data-testid="export-csv-btn" onPress={handleExport}>
                    {t('button.exportCsv')}
                  </Button>
                )}
              </div>
            )
          }
        >
          {data?.map(
            (
              {
                amount,
                createdAt,
                currencyCode,
                partner,
                firstName,
                lastName,
                email,
                consentedToBeContactedByOrg
              }: Donation,
              index: number
            ) => (
              <TableRow key={`donation-list-${createdAt}`} className={styles.listItem}>
                <TableCell>
                  {formatAmount({
                    currencyCode,
                    value: formatMinorUnitsValueToFullNumberWithDecimals(
                      amount,
                      currencyInfo?.find(el => el.code === currencyCode)?.minorUnits as number
                    )
                  })}
                </TableCell>
                <TableCell>
                  {firstName ? (
                    <>
                      {firstName} {lastName}
                    </>
                  ) : (
                    <Badge variant="default" icon="view-off">
                      {t('typography.anonymous')}
                    </Badge>
                  )}
                </TableCell>
                <TableCell>
                  {email ? (
                    email
                  ) : (
                    <Badge variant="default" icon="view-off">
                      {t('typography.anonymous')}
                    </Badge>
                  )}
                </TableCell>
                <TableCell>
                  <Badge
                    variant={consentedToBeContactedByOrg === 'yes' ? 'positive' : 'critical'}
                    icon={consentedToBeContactedByOrg === 'yes' ? 'approve' : 'reject'}
                  >
                    {t(`typography.${consentedToBeContactedByOrg === 'yes' ? 'yes' : 'no'}`)}
                  </Badge>
                </TableCell>
                <TableCell>{partner.name}</TableCell>
                <TableCell align="right">{getFormattedDate(createdAt)}</TableCell>
              </TableRow>
            )
          )}
        </Table>
        <DateRangePopper
          open={isDatePickerOpened}
          anchorEl={anchorEl.current}
          placement="bottom-end"
          setOpen={setIsDatePickerOpened}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </Grid>
    </Grid>
  )
}
