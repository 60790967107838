import { useCallback, useMemo } from 'react'
import { Box, TableCell, TableRow, Typography } from '@material-ui/core'
import { Badge, Button } from '@percent/lemonade'

import { dayJS } from '@percent/cause-dashboard/common/utility/date'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import { useTranslation } from 'react-i18next'

import styles from '../../PayoutsPage.module.scss'
import { DonationsIncludedListProps } from './DonationsIncludedList.types'
import { DonationWithFees } from '@percent/cause-dashboard/services/payouts/payoutsService.types'
import { currencyMapToFormat } from '@percent/cause-dashboard/common/utility/money/currencyMap'
import { useCSVDownloader } from '@percent/cause-dashboard/common/hooks/useCSVDownloader/useCSVDownloader'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'

export function DonationsIncludedList({
  currencyMap,
  data,
  totalResults,
  nextPage,
  previousPage,
  payoutId,
  payoutDate
}: DonationsIncludedListProps) {
  const { t } = useTranslation()
  const {
    authState: { user }
  } = useAuthState()
  const {
    reportingService: { getPayoutDonations }
  } = useServices()

  const [{ loading: csvDownloaderLoading }, { fetchPayoutDonationsCSV }] = useCSVDownloader({
    service: getPayoutDonations(user?.organisationId as string, payoutId),
    fileName: () => `payout_report_${payoutDate}`,
    successMessage: t('toast.payoutDonationsListExportSuccess'),
    errorMessage: t('toast.payoutDonationsListExportError')
  })

  const handleExport = useCallback(() => {
    if (fetchPayoutDonationsCSV) {
      fetchPayoutDonationsCSV()
    }
  }, [fetchPayoutDonationsCSV])

  const columns = useMemo(
    () => [
      { id: 'typography.grossDonation', isSortable: false, props: { width: '14%' } },
      {
        id: 'typography.fees',
        isSortable: false,
        description: t('typography.feesInfo'),
        props: { width: '10%' }
      },
      { id: 'typography.donationsTableDonorName', isSortable: false, props: { width: '12%' } },
      { id: 'typography.donationsTableDonorEmail', isSortable: false, props: { width: '12%' } },
      { id: 'typography.donationsTableMarketingConsent', isSortable: false, props: { width: '14%' } },
      { id: 'typography.donationsTablePartner', isSortable: false, props: { width: '14%' } },
      { id: 'typography.donationsTableDonationCreated', isSortable: false, props: { width: '12%' } }
    ],
    [t]
  )

  const filters = useMemo(
    () => (
      <Box className={styles.tableHeader}>
        <Typography variant="h6">{t('typography.donationsIncluded')}</Typography>
        <Button
          size="small"
          data-testid="export-payout-donations-btn"
          onPress={handleExport}
          loading={csvDownloaderLoading}
          isDisabled={csvDownloaderLoading}
        >
          {t('button.exportCsv')}
        </Button>
      </Box>
    ),
    [csvDownloaderLoading, t, handleExport]
  )

  const moneyFormat = currencyMapToFormat(currencyMap)

  return (
    <Table
      data={data}
      isLoading={false}
      totalResults={totalResults}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText=""
      orderBy=""
      filtersContent={filters}
    >
      {data?.map(
        ({
          id,
          partner,
          partnerId,
          fee,
          amount,
          settledAmount,
          firstName,
          lastName,
          email,
          consentedToBeContactedByOrg,
          createdAt
        }: DonationWithFees) => {
          const currency = settledAmount?.currency ?? amount.currency
          const grossAmount = fee?.grossAmount ?? settledAmount ?? amount
          const feeAmount = fee?.feeAmount ?? { amount: 0, currency }

          return (
            <TableRow key={`donation-list-${id}`} className={styles.listItem}>
              <TableCell align="left">{moneyFormat(grossAmount)}</TableCell>
              <TableCell align="left">{moneyFormat(feeAmount)}</TableCell>
              <TableCell align="left">
                {firstName && lastName ? (
                  `${firstName} ${lastName}`
                ) : (
                  <Badge variant="default" icon="view-off">
                    {t('typography.anonymous')}
                  </Badge>
                )}
              </TableCell>
              <TableCell align="left">
                {email ? (
                  email
                ) : (
                  <Badge variant="default" icon="view-off">
                    {t('typography.anonymous')}
                  </Badge>
                )}
              </TableCell>
              <TableCell align="left">
                <Badge
                  variant={consentedToBeContactedByOrg === 'yes' ? 'positive' : 'critical'}
                  icon={consentedToBeContactedByOrg === 'yes' ? 'approve' : 'reject'}
                >
                  {t(`typography.${consentedToBeContactedByOrg === 'yes' ? 'yes' : 'no'}`)}
                </Badge>
              </TableCell>
              <TableCell align="left">{partner?.name || partnerId.substring(0, 12) + '...'}</TableCell>
              <TableCell>{dayJS(createdAt).format('DD MMM YYYY')}</TableCell>
            </TableRow>
          )
        }
      )}
    </Table>
  )
}
