import React from 'react'

import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { DeleteItemSuccessModalProps } from './DeleteItemSuccessModal.types'
import { useTranslation } from 'react-i18next'
import { SuccessModal } from '@percent/cause-dashboard/common/components/modal/SuccessModal/SuccessModal'

export function DeleteItemSuccessModal({
  openDeleteSuccessModal,
  handleCloseDeleteSuccessModal
}: DeleteItemSuccessModalProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer openModal={openDeleteSuccessModal} onClose={handleCloseDeleteSuccessModal}>
      <SuccessModal title={t('dialog.deleteItemModal.title')} onClose={handleCloseDeleteSuccessModal} />
    </ModalContainer>
  )
}
