import { Divider, Drawer, List, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames/bind'

import { PercentLogo } from '../percentLogo/PercentLogo'

import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { ReactComponent as CharityProfileLogo } from '@percent/cause-dashboard/common/assets/images/user-circle.svg'
import { ReactComponent as DonationsTabLogo } from '@percent/cause-dashboard/common/assets/images/donation.svg'
import { ReactComponent as DonationFormTabLogo } from '@percent/cause-dashboard/common/assets/images/direct-donation.svg'
import { ReactComponent as PayoutsTabLogo } from '@percent/cause-dashboard/common/assets/images/payouts.svg'
import { ReactComponent as CausesPortalIcon } from '@percent/cause-dashboard/common/assets/images/causes-portal-icon.svg'
import { ReactComponent as MoneyMilestonesLogo } from '@percent/cause-dashboard/common/assets/images/money-milestones.svg'
import { ReactComponent as ProductOffersLogo } from '@percent/cause-dashboard/common/assets/images/product-offers.svg'
import { CustomMenuItem } from '@percent/cause-dashboard/common/components/CustomMenuItem'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './SideMenu.module.scss'
import { Feature } from '../../providers/SplitSdkProvider'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'

function CPLogo(props: any) {
  return <CharityProfileLogo {...props} />
}
function DonationsLogo(props: any) {
  return <DonationsTabLogo {...props} />
}
function DonationFormLogo(props: any) {
  return <DonationFormTabLogo {...props} />
}
function MMLogo(props: any) {
  return <MoneyMilestonesLogo {...props} />
}
function POLogos(props: any) {
  return <ProductOffersLogo {...props} />
}
function PayoutsLogo(props: any) {
  return <PayoutsTabLogo {...props} />
}

export function SideMenu() {
  const { t } = useTranslation()
  const { authState } = useAuthState()
  const { claimAccepted, rejectedBankDetails } = selectAuthState(authState)
  const cx = classNames.bind(styles)
  const featureFlags = useFeatureFlag()
  const { isOpened: isNotificationBarOpened } = useNotificationBar()

  const myNonprofit: {
    name: string
    to: string
    logo: (_: any) => JSX.Element
    soon: boolean
    claimed?: boolean
    flag?: Feature
  }[] = [
    {
      name: t('menu.charityProfile'),
      to: '/profile',
      logo: CPLogo,
      soon: false
    },
    {
      name: t('menu.donations'),
      to: '/donations',
      logo: DonationsLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.donationForm'),
      to: '/donation-form',
      logo: DonationFormLogo,
      soon: false,
      claimed: true,
      flag: 'donationForm'
    },
    {
      name: t('menu.payouts'),
      to: '/payouts',
      logo: PayoutsLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.milestones'),
      to: '/milestones',
      logo: MMLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.productOffers'),
      to: '/product-offers',
      logo: POLogos,
      soon: false
    }
  ]

  return (
    <Drawer
      variant="permanent"
      className={cx({
        drawer: true,
        verifiedSideMenu: claimAccepted,
        unverifiedSideMenu: !claimAccepted,
        rejectedBankDetails: rejectedBankDetails,
        notificationBarClosed: !isNotificationBarOpened,
        notificationBarOpened: isNotificationBarOpened
      })}
    >
      <List className={styles.list}>
        <ListItem className={styles.causesPortalLogo}>
          <CausesPortalIcon />
          <Typography variant="body1" className={styles.sideMenuTitle}>
            {t('typography.causesPortal')}
          </Typography>
        </ListItem>
        <ListItem className={styles.dividerListItem}>
          <Divider className={styles.divider} />
        </ListItem>
        <li className={styles.sidebarCategory}>{t('menu.myNonprofit')}</li>
        {myNonprofit
          .filter(a => (a.flag ? featureFlags[a.flag] : true))
          .map(item => {
            if (item.claimed) {
              return (
                claimAccepted && (
                  <CustomMenuItem name={item.name} to={item.to} Logo={item.logo} soon={item.soon} key={item.name} />
                )
              )
            }

            return <CustomMenuItem name={item.name} to={item.to} Logo={item.logo} soon={item.soon} key={item.name} />
          })}
      </List>

      <div className={styles.wrapper}>
        <ListItem className={styles.logoDividerListItem}>
          <Divider className={styles.logoDivider} />
        </ListItem>
        <div className={styles.logo}>
          <a href="https://poweredbypercent.com/" target="_blank" rel="noopener noreferrer">
            <PercentLogo />
          </a>
        </div>
      </div>
    </Drawer>
  )
}
