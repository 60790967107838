import React from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { Loader } from '@percent/cause-dashboard/common/components'
import styles from './ConfirmationCodeStep.module.scss'
import { ConfirmationCodeViewProps } from './ConfirmationCodeStep.types'

export function ConfirmationCodeStep({ errorMessage, isLoading, verifyFunc }: ConfirmationCodeViewProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      confirmationCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        confirmationCode: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ confirmationCode }: { confirmationCode: string }) => {
      verifyFunc({
        otpCode: confirmationCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <div className={styles.confirmationCodeContainer}>
      <Typography variant="h5" className={styles.headerOne}>
        {t('2fa.enterConfirmationCode')}
      </Typography>
      <Typography variant="body1" className={styles.headerTwo}>
        {t('2fa.enterConfirmationCodePart2')}
      </Typography>
      <form onSubmit={handleSubmit} className={styles.confirmationCodeForm}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FormikProvider value={formik}>
              <FormField
                label={t('form.enterSixDigit')}
                status={touched.confirmationCode && errors.confirmationCode ? 'danger' : 'default'}
                statusMessage={errors.confirmationCode || errorMessage}
                data-testid="confirmationCode"
              >
                <TextInput
                  name="confirmationCode"
                  placeholder={t('form.placeholderSixDigit')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmationCode}
                />
              </FormField>
            </FormikProvider>
            <Spacer size={8} axis="vertical" />
            <Button size="large" type="submit">
              {t('button.verify')}
            </Button>
          </>
        )}
      </form>
    </div>
  )
}
