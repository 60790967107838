import React from 'react'
import { DateRange } from 'react-date-range'

import { theme } from '@percent/lemonade'
import { DateRangeType } from './DateRange.types'
import { dayJS } from '../../utility/date'

export function CustomDateRange({ selectionRange, setSelectionRange, endDate }: DateRangeType) {
  return (
    <DateRange
      onChange={(item: any) => setSelectionRange([item.selection])}
      showDateDisplay={false}
      shownDate={endDate}
      showMonthAndYearPickers
      rangeColors={[theme.colors.primary400]}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={selectionRange}
      direction="horizontal"
      maxDate={dayJS().utc().toDate()}
      startDatePlaceholder="dd/mm/yyyy"
      endDatePlaceholder="dd/mm/yyyy"
      editableDateInputs
    />
  )
}
