import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Button } from '@percent/lemonade'
import { FieldInput } from '@percent/cause-dashboard/common/components/field/fieldInput/FieldInput'
import { FieldDropDown } from '@percent/cause-dashboard/common/components/field/fieldDropDown/FieldDropDown'
import styles from './ClaimSearchHeaderForm.module.scss'
import { ClaimSearchHeaderFormProps } from './ClaimSearchHeaderForm.types'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import useCountries from '@percent/cause-dashboard/common/hooks/useCountries/useCountries'
import { useMyCountryFromIp } from '@percent/cause-dashboard/common/hooks/useMyCountryFromIp/useMyCountryFromIp'
import { useLocalUserPreference } from '@percent/cause-dashboard/common/hooks/useLocalUserPreference/useLocalUserPreference'

export function ClaimSearchHeaderForm({ onSearchClick }: ClaimSearchHeaderFormProps) {
  const { t } = useTranslation()
  const [disableSearchButton, setDisableSearchButton] = useState(false)
  const countries = useCountries()
  const countryCodes = countries.map(({ name, code }: { name: string; code: string }) => ({
    title: name,
    value: code
  }))

  const { country: myCountry } = useMyCountryFromIp({
    useLocalCache: true
  })

  const [userPreferenceLocalCountry, setUserPreferenceLocalCountry] = useLocalUserPreference('country')

  const { errors, values, touched, setFieldValue, handleSubmit, handleBlur } = useFormik({
    enableReinitialize: false,
    initialValues: {
      countryCode: userPreferenceLocalCountry ?? myCountry ?? '',
      query: ''
    },
    validationSchema: () => {
      if (disableSearchButton) {
        return Yup.object().shape({})
      }

      return Yup.object().shape({
        countryCode: Yup.string().max(6, 'Must be 6 characters or less').required('Required'),
        query: Yup.string().max(500, 'Must be fewer than 500 characters')
      })
    },
    onSubmit: ({ countryCode, query }) => {
      setUserPreferenceLocalCountry(countryCode, { expiresInHours: 24 * 7 })
      onSearchClick(countryCode, query)
    }
  })

  const searchInputRef = React.createRef<HTMLInputElement>()

  useEffect(() => {
    if (!userPreferenceLocalCountry) {
      return
    }
    setFieldValue('countryCode', userPreferenceLocalCountry ?? myCountry ?? '')
  }, [myCountry, setFieldValue, userPreferenceLocalCountry])

  useEffect(() => {
    searchInputRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errors.query) {
      setDisableSearchButton(true)
    } else {
      setDisableSearchButton(false)
    }
  }, [errors])

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Grid className={styles.mainGrid} container spacing={3}>
          <Grid item xs>
            <FieldDropDown
              initialValue={values.countryCode}
              onClick={event => {
                setFieldValue('countryCode', event.target.value)
              }}
              selectTitle="Select country"
              valueArray={countryCodes}
            />
            <Grid item className={styles.linkContainer}>
              <ReactRouterLink className={styles.link} to={RoutePath.ADD_ORGANIZATION}>
                {t('typography.clickHere')}
              </ReactRouterLink>
              <Typography className={styles.text}> {t('typography.findYourOrganization')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs className={styles.fieldInput}>
            <FieldInput
              name="query"
              label={t('typography.organizationRegisteredNumber')}
              placeHolder=""
              value={values.query}
              onChange={(a: string) => setFieldValue('query', a)}
              handleBlur={handleBlur}
              touched={touched.query}
              error={errors.query}
              data-testid="search-header-search-input"
            />
          </Grid>
          <Grid item xs={3}>
            <div style={{ height: '21.5px', width: '1px' }}></div>
            <Button
              stretch
              size="large"
              disabled={disableSearchButton}
              data-testid="search-header-search-button"
              type="submit"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
