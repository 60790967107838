import React from 'react'

import { ModalDeleteBody } from '@percent/cause-dashboard/common/components/modal/Modal/ModalDeleteBody'
import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { DeleteItemModalProps } from './DeleteItemModal.types'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'

export function DeleteItemModal({ open, onClose, id, refresh, handleOpenDeleteSuccessModal }: DeleteItemModalProps) {
  const {
    authState: { user }
  } = useAuthState()
  const { t } = useTranslation()
  const { milestonesService } = useServices()
  const [{ isLoading, errorMessage }, { apiFunc: deleteMoneyMilestonesItem }] = useMutation(
    milestonesService.deleteMilestonesItem,
    () => {
      if (refresh) {
        refresh()
        handleOpenDeleteSuccessModal()
        onClose()
      }
    }
  )

  const handleDelete = () => {
    deleteMoneyMilestonesItem({ organisationId: user?.organisationId, id: id.sId })
  }

  return (
    <ModalContainer openModal={open} onClose={onClose} headerTitle={t('dialog.deleteMilestone.title')}>
      <ModalDeleteBody
        testId="delete-item-button"
        handleDelete={handleDelete}
        handleCancel={onClose}
        isLoading={isLoading}
        errorMessage={errorMessage}
      >
        {t('dialog.deleteMilestone.bodyTextOne')} <b>{id.reward}</b>
        {t('dialog.deleteMilestone.bodyTextTwo')}
      </ModalDeleteBody>
    </ModalContainer>
  )
}
