import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Button, Icon, Loader, Radio, Spacer, Tooltip } from '@percent/lemonade'
import styles from './CommPreferencesCard.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useToast } from 'libs/shared/ui-lemonade/src/components/toast'
import { useMutation } from '@percent/cause-dashboard/common/hooks'

export default function CommPreferencesCard() {
  const { t } = useTranslation()
  const { accountService } = useServices()
  const [{ data: marketingConsentData, isLoading, error }] = useQuery(accountService.getMarketingConsent, {})
  const [consent, setConsent] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const toast = useToast()
  const [{ isLoading: isSaving }, { apiFunc: saveMarketingConsent }] = useMutation(
    accountService.setMarketingConsent,
    _success => {
      setIsButtonDisabled(true)
      toast.addToast(t('typography.marketingConsent.success'), 'success')
    },
    _error => {
      toast.addToast(t('typography.marketingConsent.error'), 'error')
    }
  )

  useEffect(() => {
    const fetchMarketingConsent = () => {
      if (!error && marketingConsentData?.data.data.acceptedAt) {
        setConsent(true)
      } else {
        setConsent(false)
      }
    }

    fetchMarketingConsent()
  }, [marketingConsentData, error])

  const saveCommsPreferences = () => {
    saveMarketingConsent({ optIn: consent })
  }

  const setPreferences = (newChecked: boolean) => {
    setConsent(newChecked)
    setIsButtonDisabled(false)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.contentCtn}>
      <div className={styles.subtitleCtn}>
        <p className={styles.subtitle}>{t('typography.marketingConsent.subtitle')}</p>
        <Tooltip content={t('typography.marketingConsentDetail')}>
          <div className={styles.subtitleWithIconCtn}>
            <Icon name="info" size={6} color="gray400" />
          </div>
        </Tooltip>
      </div>
      <div className={styles.radioButtonsCtn}>
        <Radio
          id="marketingConsentOn"
          name="marketingConsent"
          value="on"
          label={t('typography.marketingConsent.buttonOn')}
          checked={consent}
          onChange={() => setPreferences(true)}
        />
        <Spacer size={4} axis="horizontal" />
        <Radio
          id="marketingConsentOff"
          name="marketingConsent"
          value="off"
          label={t('typography.marketingConsent.buttonOff')}
          checked={!consent}
          onChange={() => setPreferences(false)}
        />
      </div>
      <div className={styles.buttonCtn}>
        <Button
          variant="primary"
          onPress={() => saveCommsPreferences()}
          disabled={isButtonDisabled}
          loading={isSaving}
          data-testid="save-changes-button"
        >
          {t('button.saveChanges')}
        </Button>
      </div>
    </div>
  )
}
