import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import styles from '../BankDetails.module.scss'
import { BankDetailsViewProps } from './BankDetailsView.types'
import { UploadDocument } from '../uploadDocument/UploadDocument'
import { BankDetailStatus } from '@percent/cause-dashboard/services/bankDetails/bankDetails.types'

export function BankDetailsUploadView({
  setSelectedFile,
  buttonFlag,
  disableUploadBtn,
  disabledField,
  selectedFile,
  apiFunc,
  fileFieldText,
  isPostLoading,
  bankDetailsData
}: BankDetailsViewProps) {
  const { t } = useTranslation()

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    if (selectedFile) {
      formData.append('proofOfBankDetails', selectedFile)
    }

    apiFunc({
      payload: {
        formData
      }
    })
  }

  return (
    <>
      <div className={styles.uploadDocument}>
        {bankDetailsData?.bankAccountDetails?.holderName && bankDetailsData.status === BankDetailStatus.APPROVED && (
          <>
            <FormField label="Account holder name" disabled data-testid="holderName" necessity="required">
              <TextInput name="holderName" value={bankDetailsData?.bankAccountDetails?.holderName} placeholder="" />
            </FormField>
            <Spacer axis="vertical" size={5} />
          </>
        )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bic &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bicLabel}
                disabled
                data-testid="bic"
                necessity="required"
              >
                <TextInput name="bic" value={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bic} />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.accountNumber &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.accountNumberLabel}
                disabled
                data-testid="accountNumber"
                necessity="required"
              >
                <TextInput
                  name="accountNumber"
                  value={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.accountNumber}
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel}
                disabled
                data-testid="bankCode"
              >
                <TextInput name="bankCode" value={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bankCode} />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.branchCode &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.branchCodeLabel}
                disabled
                data-testid="branchCode"
              >
                <TextInput
                  name="branchCode"
                  value={bankDetailsData?.bankAccountDetails?.wireTransferDetails?.branchCode}
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        <UploadDocument
          helperText={t('typography.helperBankAccountText')}
          labelText={t('typography.proofBankAccount')}
          uploadButton={t('button.uploadDocument')}
          fileFieldText={fileFieldText}
          setSelectedFile={setSelectedFile}
          buttonFlag={buttonFlag}
          disableUploadBtn={disableUploadBtn}
          inputDisableStyle={disabledField}
        />
      </div>
      {buttonFlag && (
        <form onSubmit={handleSubmit} className={styles.submitFileButton}>
          {
            <Button
              type="submit"
              variant={selectedFile && !isPostLoading ? 'primary' : 'secondary'}
              disabled={selectedFile && !isPostLoading ? false : true}
            >
              {t('button.submitBankDocuments')}
            </Button>
          }
        </form>
      )}
    </>
  )
}
