import { AcknowledgeModalProps } from './acknowledge-modal.types'
import { Button } from '../button'
import { Heading } from '../heading'
import { Text } from '../text'
import { AnimatedContainer } from '../animated-container'

export const AcknowledgeModal = ({
  result,
  title,
  description,
  buttonText,
  handleClose,
  buttonTestId,
  children,
  secondaryButtonText,
  onSecondaryButtonClick,
  viewTestId,
}: AcknowledgeModalProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        padding: '48px 48px 48px 48px',
      }}
      data-testid={viewTestId}
    >
      <AnimatedContainer>
        {result === 'positive' ? (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="40" cy="40" r="40" fill="#008050" />
            <circle opacity="0.08" cx="40" cy="40" r="36" fill="#008050" />
            <path
              d="M53.3774 31.8133L50.0803 28.5067C50.0254 28.4517 49.9603 28.4081 49.8885 28.3783C49.8168 28.3486 49.7399 28.3333 49.6622 28.3333C49.5846 28.3333 49.5077 28.3486 49.4359 28.3783C49.3642 28.4081 49.299 28.4517 49.2442 28.5067L35.3188 42.4415C35.264 42.4965 35.1988 42.5402 35.1271 42.5699C35.0553 42.5997 34.9784 42.615 34.9008 42.615C34.8231 42.615 34.7462 42.5997 34.6745 42.5699C34.6027 42.5402 34.5376 42.4965 34.4827 42.4415L31.1856 39.135C31.1308 39.08 31.0656 39.0364 30.9939 39.0066C30.9221 38.9768 30.8452 38.9615 30.7676 38.9615C30.6899 38.9615 30.613 38.9768 30.5413 39.0066C30.4695 39.0364 30.4044 39.08 30.3495 39.135L27.0524 42.4415C26.9417 42.5523 26.8795 42.7024 26.8795 42.859C26.8795 43.0156 26.9417 43.1657 27.0524 43.2765L34.4827 50.708C34.5376 50.7629 34.6027 50.8066 34.6745 50.8363C34.7462 50.8661 34.8231 50.8814 34.9008 50.8814C34.9784 50.8814 35.0553 50.8661 35.1271 50.8363C35.1988 50.8066 35.264 50.7629 35.3188 50.708L53.3774 32.6399C53.4856 32.5296 53.5462 32.3812 53.5462 32.2266C53.5462 32.0721 53.4856 31.9237 53.3774 31.8133Z"
              fill="#008050"
            />
          </svg>
        ) : (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="40" cy="40" r="40" fill="#E4252C" />
            <circle opacity="0.08" cx="40" cy="40" r="36" fill="#E4252C" />
            <path
              d="M42.5531 40.2121C42.5273 40.1863 42.5067 40.1557 42.4927 40.1219C42.4787 40.0882 42.4715 40.052 42.4715 40.0155C42.4715 39.9789 42.4787 39.9428 42.4927 39.909C42.5067 39.8753 42.5273 39.8446 42.5531 39.8188L52.8445 29.5286C53.157 29.2156 53.3324 28.7914 53.3321 28.3491C53.3318 27.9069 53.1558 27.4829 52.8429 27.1704C52.5299 26.8579 52.1057 26.6825 51.6634 26.6829C51.2212 26.6832 50.7972 26.8592 50.4847 27.1721L40.1944 37.4579C40.1686 37.4838 40.138 37.5043 40.1042 37.5183C40.0705 37.5323 40.0343 37.5395 39.9978 37.5395C39.9613 37.5395 39.9251 37.5323 39.8913 37.5183C39.8576 37.5043 39.8269 37.4838 39.8011 37.4579L29.5109 27.1721C29.3561 27.0173 29.1724 26.8945 28.9702 26.8107C28.7681 26.7269 28.5513 26.6837 28.3325 26.6836C27.8904 26.6835 27.4665 26.859 27.1538 27.1715C26.8412 27.484 26.6655 27.9079 26.6654 28.3499C26.6653 28.792 26.8408 29.2159 27.1533 29.5286L37.4424 39.8188C37.4683 39.8446 37.4888 39.8753 37.5028 39.909C37.5168 39.9428 37.524 39.9789 37.524 40.0155C37.524 40.052 37.5168 40.0882 37.5028 40.1219C37.4888 40.1557 37.4683 40.1863 37.4424 40.2121L27.1533 50.5035C26.9986 50.6583 26.8758 50.8421 26.7921 51.0443C26.7084 51.2465 26.6654 51.4633 26.6654 51.6821C26.6655 52.1242 26.8412 52.548 27.1538 52.8605C27.3086 53.0153 27.4924 53.138 27.6946 53.2217C27.8969 53.3054 28.1136 53.3485 28.3325 53.3484C28.7745 53.3483 29.1984 53.1726 29.5109 52.86L39.8011 42.5686C39.8269 42.5427 39.8576 42.5222 39.8913 42.5082C39.9251 42.4942 39.9613 42.487 39.9978 42.487C40.0343 42.487 40.0705 42.4942 40.1042 42.5082C40.138 42.5222 40.1686 42.5427 40.1944 42.5686L50.4847 52.86C50.7972 53.1726 51.2211 53.3483 51.6631 53.3484C52.1051 53.3485 52.5291 53.173 52.8417 52.8605C53.1544 52.548 53.3301 52.1242 53.3302 51.6821C53.3303 51.2401 53.1548 50.8161 52.8423 50.5035L42.5531 40.2121Z"
              fill="#E4252C"
            />
          </svg>
        )}
      </AnimatedContainer>
      <Heading level={'h5'}>{title}</Heading>
      <span style={{ textAlign: 'center' }}>
        {children || <Text size="small">{description}</Text>}
      </span>
      <div
        style={{
          paddingTop: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {buttonText && (
          <Button data-testid={buttonTestId} onPress={handleClose}>
            {buttonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button onPress={onSecondaryButtonClick} variant="tertiary">
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  )
}
