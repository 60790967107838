import { AppBar, Container, Toolbar } from '@material-ui/core'
import React from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { Button } from '@percent/lemonade'
import { ReactComponent as PercentLogo } from '@percent/cause-dashboard/common/assets/images/percentlogo.svg'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './NavigationBar.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { SET_LOGOUT } from '@percent/cause-dashboard/context/auth'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useAnalytics } from '@percent/cause-dashboard/common/hooks/useAnalytics/useAnalytics'

export function NavigationBar() {
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { authService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const analytics = useAnalytics()

  const handleLogout = () => {
    authService.logout().then(() => {
      analytics?.reset()
      authDispatch({
        type: SET_LOGOUT
      })
    })

    return <Redirect to="/" />
  }

  const handleLogIn = () => {
    if (isAuthorised) {
      handleLogout()
      push('/')
    } else {
      push('/signin')
    }
  }

  return (
    <AppBar position="static" className={styles.appBar}>
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <PercentLogo />
          <div className={styles.logInOutButtonDiv}>
            <Button variant="secondary" data-testid="navigation-bar-log-in-button" onPress={handleLogIn}>
              {t(isAuthorised ? 'button.logout' : 'button.logIn')}
            </Button>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
