import React from 'react'

import { Button, Spacer } from '@percent/lemonade'
import { ReactComponent as SetupCompleteIcon } from '@percent/cause-dashboard/common/assets/images/setup-complete.svg'
import styles from './SetupComplete.module.scss'
import { useTranslation } from 'react-i18next'
import { SetupCompleteProps } from './SetupComplete.types'

export function SetupComplete({ logInToApp }: SetupCompleteProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SetupCompleteIcon className={styles.icon} />
        <h1 className={styles.header}>{t('2fa.completeHeader')}</h1>
        <p className={styles.subtext}>{t('2fa.completeSubtext')}</p>
        <Spacer size={6} />
        <Button type="button" onPress={logInToApp}>
          {t('button.done')}
        </Button>
      </div>
    </div>
  )
}
