import { Typography } from '@material-ui/core'
import { Button, Label } from '@percent/lemonade'
import React, { ChangeEvent, useRef, useState } from 'react'
import styles from '../BankDetails.module.scss'
import { UploadDocumentProps } from './UploadDcoument.types'

export function UploadDocument({
  helperText,
  uploadButton,
  labelText,
  fileFieldText,
  setSelectedFile,
  buttonFlag,
  disableUploadBtn,
  inputDisableStyle
}: UploadDocumentProps) {
  const [errorFileType, setErrorFileType] = useState<string | null>()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return
    if (!fileList?.[0]?.type?.match(/(png|jpeg|pdf)/i)) {
      setErrorFileType('File format is not valid')
      return
    }
    if (fileList?.[0]?.size > 10e6) {
      setErrorFileType('File size is too large. Maximum file size allowed is 10 MB.')
      return
    }
    setSelectedFile(fileList?.[0])
    setErrorFileType('')
  }

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  return (
    <div className={styles.uploadButton}>
      <input
        accept=".png, .jpg, .pdf"
        onChange={changeHandler}
        type="file"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        data-testid="fileUpload"
      />
      <Label htmlFor="uploadDocument" necessity="required">
        {labelText}
      </Label>
      <div className={inputDisableStyle ? styles.labelDisabled : styles.label}>
        <Typography>{fileFieldText}</Typography>
        {buttonFlag && (
          <Button variant="secondary" disabled={disableUploadBtn} onPress={handleClick}>
            {uploadButton}
          </Button>
        )}
      </div>
      <Typography className={styles.fieldError} variant="body2" color="primary">
        {errorFileType}
      </Typography>
      {buttonFlag && <Typography className={styles.helperText}>{helperText}</Typography>}
    </div>
  )
}
