import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

import { User } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { Wizard } from './Wizard/Wizard'
import { useTranslation } from 'react-i18next'
import { SetupQRStepContainer } from './SetupQRStep/SetupQRStepContainer'
import { ConfirmationCodeStepContainer } from './ConfirmationCodeStep/ConfirmationCodeStepContainer'
import { SaveBackupCodeStep } from './SaveBackupCodeStep/SaveBackupCodeStep'
import { SetupComplete } from './SetupComplete/SetupComplete'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth/authReducer/authReducer'
import styles from './TwoFactorAuthenticationSetup.module.scss'
import { useAuthState } from '@percent/cause-dashboard/common/hooks/useAuthState/useAuthState'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { LocationState } from './TwoFactorAuthentication.types'

export function TwoFactorAuthenticationSetup() {
  const { state } = useLocation<LocationState>()
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { authDispatch } = useAuthDispatch()
  const [userData, setUserData] = useState<undefined | User>(undefined)

  const logInToApp = () => {
    authDispatch({
      type: SET_AUTHORISED,
      payload: {
        user: userData
      }
    })

    push('/profile')
  }

  useEffect(() => {
    if (isAuthorised && !state.otpData) {
      push('/profile')
    }
  }, [isAuthorised, push, state])

  const steps = [
    {
      title: t('2fa.firstStepTitle'),
      step: <SetupQRStepContainer />
    },
    {
      title: t('2fa.secondStepTitle'),
      step: <ConfirmationCodeStepContainer setUserData={setUserData} />
    },
    {
      title: t('2fa.thirdStepTitle'),
      step: <SaveBackupCodeStep otpCode={userData?.twoFaBackupCode} />
    }
  ]

  useEffect(() => {
    if (!state?.password && !state?.otpData) {
      push('/signin')
    }
  }, [state, push])

  return (
    <div className={styles.container}>
      <Wizard steps={steps}>
        <SetupComplete logInToApp={logInToApp} />
      </Wizard>
    </div>
  )
}
