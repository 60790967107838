import React from 'react'
import { FormikProvider, useFormik } from 'formik'

import { ItemModalProps, validationSchema, ShoppingItem } from '../ModalHelpers'
import styles from '../ItemModal.module.scss'

import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Button, FormField, Spacer, TextArea } from '@percent/lemonade'
import { FieldInputCurrency } from '@percent/cause-dashboard/common/components/field/fieldInputCurrency/FieldInputCurrency'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { CreateMilestonesItemProps } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { floatToIntegerCurrency } from '@percent/cause-dashboard/common/utility/utility'
import { Typography } from '@material-ui/core'
import getSymbolFromCurrency from 'currency-symbol-map'
import { TypeOf } from 'yup'

export function CreateItemModal({ cancel, save }: ItemModalProps) {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [{ isLoading }, { apiFunc: createMoneyMilestonesItem }] = useMutation(
    milestonesService.createMilestonesItem,
    () => {
      save(true)
    },
    () => {
      save(false)
    }
  )
  const { t } = useTranslation()
  const handleCancelForm = () => {
    resetForm()
    cancel()
  }

  const formik = useFormik({
    initialValues: {
      amount: undefined,
      reward: undefined
    },
    validationSchema,
    onSubmit: submitValues => {
      const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

      const amount = floatToIntegerCurrency(inputAmount)
      createMoneyMilestonesItem(buildShoppingListItem(user?.organisationId as string, amount, reward))
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue } = formik

  const buildShoppingListItem = (id: string, amount: number, reward: string): CreateMilestonesItemProps => ({
    id,
    amount,
    reward
  })
  const { amount } = values as unknown as ShoppingItem

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.text}>
        {t('dialog.moneyMilestone.helperTextOne')}
        {getSymbolFromCurrency(organisation?.currencyCode || 'USD')}
        {t('dialog.moneyMilestone.helperTextTwo')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <FieldInputCurrency
          name="itemAmount"
          label={t('form.itemAmount')}
          placeHolder={t('form.itemAmountPlaceholder')}
          value={amount}
          onChange={(a: string) => setFieldValue('amount', a)}
          handleBlur={handleBlur}
          error={errors.amount}
          addOn={organisation?.currencyCode}
          isRequiredField
        />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.milestoneReward')}
            status={touched.reward && errors.reward ? 'danger' : 'default'}
            statusMessage={errors.reward}
            data-testid="itemReward"
            necessity="required"
          >
            <TextArea
              name="reward"
              maxLength={255}
              placeholder={t('form.itemRewardPlaceholder')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reward}
            />
          </FormField>
        </FormikProvider>
        <Spacer axis="vertical" size={5} />
        <div className={styles.buttonContainer}>
          <Button type="submit" data-testid="save-item-button">
            {t('button.addMilestone')}
          </Button>
          <Spacer size={2} />
          <Button variant="secondary" data-testid="cancel-button" onPress={handleCancelForm}>
            {t('button.cancel')}
          </Button>
        </div>
      </form>
    </div>
  )
}
