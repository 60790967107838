import { AcknowledgeModal, Modal } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'

type WaitListModalProps = {
  open: boolean
  onClose: () => void
}

export function WaitListModal({ open, onClose }: Readonly<WaitListModalProps>) {
  const { t } = useTranslation()

  return (
    <Modal width={600} open={open} onClose={onClose}>
      <AcknowledgeModal
        result={'positive'}
        title={t('productOffers.waitList.modalTitle')}
        buttonText={t('button.close')}
        handleClose={onClose}
      >
        {t('productOffers.waitList.modalText')}
      </AcknowledgeModal>
    </Modal>
  )
}

export default WaitListModal
