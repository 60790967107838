import React from 'react'

import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { ProofBankErrorModalProps } from './ProofBankErrorModal.types'
import { useTranslation } from 'react-i18next'
import { SuccessDeleteModal } from '@percent/cause-dashboard/common/components/modal/SuccessModal/SuccessDeleteModal'

export function ProofBankErrorModal({
  openProofBankErrorModal,
  handleCloseProofBankErrorModal
}: ProofBankErrorModalProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer openModal={openProofBankErrorModal} onClose={handleCloseProofBankErrorModal}>
      <SuccessDeleteModal
        title={t('dialog.proofBankErrorModal.title')}
        onClose={handleCloseProofBankErrorModal}
        subTitle={t('dialog.proofBankErrorModal.subTitle')}
      />
    </ModalContainer>
  )
}
