import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from './appStoreUrls'

const productOffersCatalog = {
  airslate: {
    type: ProductOfferType.PartnerProduct,
    name: 'Airslate',
    description:
      'Learn how Airslate can help you create and manage workflows with its doc generation, e-signature, web form & automation platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/AirslateLogo.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cks5-zxf-czc',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  asana: {
    type: ProductOfferType.PartnerProduct,
    name: 'Asana',
    description: 'Get 50% off Asana’s work management tool with dedicated support from their team',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/AsanaLogo.png'),
    primaryButton: {
      linkType: 'typeform',
      link: 'ttps://form.typeform.com/to/Va07vpDP',
      typeformId: 'Va07vpDP',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  atlassian: {
    type: ProductOfferType.PartnerProduct,
    name: 'Atlassian',
    description: 'Get 75% off work management products including Jira, Confluence, and Trello',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Atlassian.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.atlassian.com/teams/nonprofits?utm_source=percent&utm_medium=comarketing&utm_campaign=P:multiproduct%7CO:foundation%7CF:discovery%7CW:nonprofit%7CI:percent-marketplace%7C',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  auth0: {
    type: ProductOfferType.PartnerProduct,
    name: 'Auth0',
    description: 'Get 50% off Auth0 to protect your data, donor information and other sensitive resources',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Auth0.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://auth0.com/percent-validation-form',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  box: {
    type: ProductOfferType.PartnerProduct,
    name: 'Box',
    description: 'Get at least 30% off Box’s cloud storage solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Box.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/ckpd-pvy-jzz',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  breezy: {
    type: ProductOfferType.PartnerProduct,
    name: 'Breezy',
    description: 'Get a 14 day free trial for Breezy HR to attract and hire better employees in less time',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Breezy.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://breezyhr.grsm.io/jzhpw10oscnx',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  canva: {
    type: ProductOfferType.PartnerProduct,
    name: 'Canva',
    description: 'Get Canva for free to start creating high-impact graphics and marketing materials',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Canva.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-canva',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  docusign: {
    type: ProductOfferType.PartnerProduct,
    name: 'Docusign',
    description: 'Get at least 30% off Docusign’s electronic signature solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Docusign.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cpkt-sjn-vnb',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  eventbrite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Eventbrite',
    description: "Get 50% off Eventbrite's premium event ticketing and marketing tools",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Eventbrite.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.eventbrite.com/l/npo/',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  fiverr: {
    type: ProductOfferType.PartnerProduct,
    name: 'Fiverr',
    description: 'Get 10% off your first purchase with Fiverr Pro, use PERCENT10 to redeem your offer',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Fiverr.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://fiverr.grsm.io/0zau3iqhk0xn',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  freshworks: {
    type: ProductOfferType.PartnerProduct,
    name: 'Freshworks',
    description: 'Get 40% off Freshmarketer to help streamline your marketing tasks',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Freshworks.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://affiliatepartner-freshmarketer.freshworks.com/percent',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  givebutter: {
    type: ProductOfferType.PartnerProduct,
    name: 'Givebutter',
    description: 'Schedule a call to discuss Givebutter’s free, all-in-one fundraising platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Givebutter.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  google: {
    type: ProductOfferType.PartnerProduct,
    name: 'Google',
    description: 'Get 70% off Google’s workspace, a collection of productivity and collaboration tools',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-google',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  hootsuite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Hootsuite',
    description: 'Get 75% off Hootsuite to help with your social media management',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Hootsuite.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-s75-twd',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  hubspot: {
    type: ProductOfferType.PartnerProduct,
    name: 'Hubspot',
    description: 'Save 20% on all the tools you need to attract, convert, and delight your customers today',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/HubSpot.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://hubspot.sjv.io/c/4976018/1971637/12893',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  linkedInFundraise: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Fundraise',
    description: 'Get 75% off LinkedIn’s Sales Navigator Core to connect with new donors',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  linkedInHire: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Hire',
    description: 'Get 75% off LinkedIn’s Recruiter Lite to find and hire new talent',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  newRelic: {
    type: ProductOfferType.PartnerProduct,
    name: 'New Relic',
    description: 'Get 1,000 GB data and Three Full Platform Users for free each month',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/NewRelic.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://newrelic.com/social-impact/signup',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  nordlayer: {
    type: ProductOfferType.PartnerProduct,
    name: 'NordLayer',
    description: 'Get 60% off NordLayer’s network security solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Nordlayer.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-sw8-6hr',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  okta: {
    type: ProductOfferType.PartnerProduct,
    name: 'Okta',
    description: 'Get 50% off Okta to add an extra layer of security to your organization',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Okta.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-okta',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  openAi: {
    type: ProductOfferType.PartnerProduct,
    name: 'OpenAI',
    description: 'Access ChatGPT Team at $20 per month per user, or get 50% off ChatGPT Enterprise',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Openai.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/openai',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  pagerDuty: {
    type: ProductOfferType.PartnerProduct,
    name: 'PagerDuty',
    description:
      "5 seats on PagerDuty's incident response tool to allow you to orchestrate cross-functional response in seconds",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Pagerduty.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.pagerduty.com/industries/non-profit/',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  pandaDoc: {
    type: ProductOfferType.PartnerProduct,
    name: 'PandaDoc',
    description: 'Get 20% off PandaDoc to create, manage and e-Sign docs with ease',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/PandaDoc.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://pandadoc.partnerlinks.io/hazy0otxddta',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  quickBooks: {
    type: ProductOfferType.PartnerProduct,
    name: 'QuickBooks',
    description: 'Get 90% off QuickBook’s online accounting software',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/quickbooks-2.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://quickbooks.partnerlinks.io/vdphs1uocysx',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  sage: {
    type: ProductOfferType.PartnerProduct,
    name: 'Sage',
    description: 'Get 50% off Sage Intacct to help with your accounting needs',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Sage.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=fN0yPvZBLUmho8WOsCz0-PAYGA5Uma5Dk674Gui_6yJUQk8wQktGOVBXRlNIVTg4NUJPSTlHSTcxOC4u',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  splunk: {
    type: ProductOfferType.PartnerProduct,
    name: 'Splunk',
    description: 'Get Splunk for free to help your organisation manage large amounts of data',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Splunk.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.splunk.com/en_us/about-us/splunk-pledge/nonprofit-license-application.html',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  teamtailor: {
    type: ProductOfferType.PartnerProduct,
    name: 'Teamtailor',
    description: "Get 40% off Teamtailor's easy-to-use, candidate-focused recruitment software",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Teamtailor.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://refer.teamtailor.com/jasanmann3894-pqgy0i',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  toptal: {
    type: ProductOfferType.PartnerProduct,
    name: 'Toptal',
    description: 'Get 10% off to help hiring the top 3% freelance talent',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Toptal.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://ps.toptal.com/5mjm0l3viut8',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  twilio: {
    type: ProductOfferType.PartnerProduct,
    name: 'Twilio',
    description: "Get $100 credits and further discounted offers on Twilio's communication and engagment tool",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Twilio.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.twilio.org/support-and-resources/impact-access-program',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  workable: {
    type: ProductOfferType.PartnerProduct,
    name: 'Workable',
    description: 'Get 25% off Workable’s leading recruiting software and hiring platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/workable_symbol_color.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://ps.workable.com/eawocsjdvx5975-marketplace-starter',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  xero: {
    type: ProductOfferType.PartnerProduct,
    name: 'Xero',
    description: "Get 50% off Xero's simple and smart online accounting software",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Xero.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://xeroamericas.partnerlinks.io/percent',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  grantFinder: {
    type: ProductOfferType.PercentProduct,
    name: 'Grant finder and application tool',
    description: 'Discover tailored grant opportunities and streamline your grant writing process',
    logo: require('@percent/cause-dashboard/common/assets/images/percent-products/GrantFinder.png'),
    primaryButton: {
      linkType: 'internal',
      link: '',
      linkText: 'productOffers.joinWaitlist'
    },
    secondaryButton: undefined
  },
  causeCommunity: {
    type: ProductOfferType.PercentProduct,
    name: 'Cause community',
    description: 'Join a digital community of causes just like yours to meet other people who share your mission',
    logo: require('@percent/cause-dashboard/common/assets/images/percent-products/CauseCommunity.png'),
    primaryButton: {
      linkType: 'internal',
      link: '',
      linkText: 'productOffers.joinWaitlist'
    },
    secondaryButton: undefined
  },
  HRandPayroll: {
    type: ProductOfferType.PercentProduct,
    name: 'HR and payroll tool',
    description:
      "Streamline your nonprofit's HR and payroll processes with our user-friendly, comprehensive management tool",
    logo: require('@percent/cause-dashboard/common/assets/images/percent-products/HRandPayroll.png'),
    primaryButton: {
      linkType: 'internal',
      link: '',
      linkText: 'productOffers.joinWaitlist'
    },
    secondaryButton: undefined
  },
  donationProcessing: {
    type: ProductOfferType.PercentProduct,
    name: 'Donation processing',
    description: 'Add a Percent-powered donation form to your website',
    logo: require('@percent/cause-dashboard/common/assets/images/percent-products/DonationProcessing.png'),
    primaryButton: {
      linkType: 'internal',
      link: '',
      linkText: 'productOffers.joinWaitlist'
    },
    secondaryButton: undefined
  },
  volunteerFinder: {
    type: ProductOfferType.PercentProduct,
    name: 'Volunteer finder',
    description: "Get skilled volunteers from the world's best tech companies",
    logo: require('@percent/cause-dashboard/common/assets/images/percent-products/VolunteerFinder.png'),
    primaryButton: {
      linkType: 'internal',
      link: '',
      linkText: 'productOffers.joinWaitlist'
    },
    secondaryButton: undefined
  }
} as const

type ProductOffersKeys = keyof typeof productOffersCatalog

export const productOffers = productOffersCatalog as Record<ProductOffersKeys, ProductOffer>
