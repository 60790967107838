import styles from './PartnerApplicationModal.module.scss'
import { ReactComponent as CloseLogo } from '@percent/cause-dashboard/common/assets/images/close.svg'
import { ZENDESK_TICKET_LINK } from '@percent/cause-dashboard/constants/zendesk'
import { Badge, IconRegistry } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { ValidationSubmission } from '@percent/cause-dashboard/services/applications/validationSubmission.types'

type ModalContentProps = {
  onClose: () => void
  validationData: ValidationSubmission | undefined
}

type BadgeProps = {
  variant: 'warning' | 'positive' | 'critical' | 'default'
  icon?: keyof typeof IconRegistry
  text: 'In progress' | 'Approved' | 'Rejected' | 'Unknown status'
}

export const statusMap: Record<string, BadgeProps> = {
  PENDING: {
    variant: 'warning',
    icon: 'clock',
    text: 'In progress'
  },
  APPROVED: {
    variant: 'positive',
    icon: 'check-badge',
    text: 'Approved'
  },
  REJECTED: {
    variant: 'critical',
    icon: 'reject',
    text: 'Rejected'
  },
  undefined: {
    variant: 'default',
    text: 'Unknown status'
  }
}

export function PartnerApplicationModalContent({ onClose, validationData }: Readonly<ModalContentProps>) {
  const { t } = useTranslation()

  const formatDate = (date: string): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' }
    return new Date(date).toLocaleDateString('en-GB', options)
  }

  const getBadgeProps = (status: string): BadgeProps => {
    return statusMap[status] || statusMap.undefined
  }

  const subtitle = validationData?.programName ?? ''

  const submittedAt = validationData?.submittedAt ?? ''
  const submissionDate = formatDate(submittedAt)

  const status = validationData?.status ?? ''
  const { variant, icon, text } = getBadgeProps(status)

  return (
    <div className={styles.modalCtn}>
      <div className={`${styles.column} ${styles.leftColumn}`}>
        <div className={styles.leftCtn}>
          <p className={styles.title} data-testid="title">
            {t('modalPartnerApplication.title')}
          </p>
          <div className={styles.box} data-testid="box">
            <p className={styles.subtitle}> {subtitle} </p>
            <p id={styles.secondaryColor}> {t('modalPartnerApplication.date', { date: `${submissionDate}` })} </p>
            {icon ? (
              <Badge variant={variant} icon={icon}>
                {text}
              </Badge>
            ) : (
              <Badge variant={variant}>{text}</Badge>
            )}
          </div>
          {status === 'PENDING' ? (
            <div>
              <p className={styles.boldText}>{t('modalPartnerApplication.paragraph1')}</p>
              <p id={styles.secondaryColor}>{t('modalPartnerApplication.paragraph2')}</p>
            </div>
          ) : null}
          <div className={styles.modalFooter}>
            <p id={styles.secondaryColor} data-testid="footer">
              {t('modalPartnerApplication.footer')}{' '}
              <a
                className={styles.link}
                id={styles.secondaryColor}
                href={`${ZENDESK_TICKET_LINK}?ticket_form_id=4837178341521`}
              >
                {t('modalPartnerApplication.contactUs')}
              </a>
              {/*
               */}
              .
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.column} ${styles.rightColumn}`}>
        <button id={styles.btnCloseDialog} onClick={onClose} type="button" data-testid="close-button">
          <CloseLogo />
        </button>
      </div>
    </div>
  )
}
