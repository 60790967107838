import React from 'react'

import { DisableTwoFactorDeviceContainerProps } from '../ChangeTwoFactorDevice/ChangeTwoFactorDevice.types'

import { DisableTwoFactorDevice } from './DisableTwoFactorDevice'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/cause-dashboard/common/hooks'

export function DisableTwoFactorDeviceContainer({
  isOpened,
  onClose,
  setChecked
}: DisableTwoFactorDeviceContainerProps) {
  const { authService } = useServices()

  const [{ isLoading, errorMessage }, { apiFunc: disableFunc }] = useMutation(authService.disable2fa, () => {
    onClose()
    setChecked(false)
  })

  return (
    <DisableTwoFactorDevice
      disableFunc={disableFunc}
      errorMessage={errorMessage}
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={onClose}
    />
  )
}
