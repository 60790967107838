import React from 'react'
import classNames from 'classnames/bind'

import { useWizard } from '../useWizard/useWizard'

import { ReactComponent as CheckMarkWhite } from '@percent/cause-dashboard/common/assets/images/check_mark_white.svg'
import styles from './Breadcrumbs.module.scss'

const cx = classNames.bind(styles)

export const Breadcrumbs = () => {
  const { currentStep, steps, isAuthenticationComplete } = useWizard()

  return (
    <div className={styles.breadcrumbs}>
      {steps.map((step, index) => (
        <div key={step.title} className={styles.breadcrumbItem}>
          <span
            className={cx({
              doneStep: currentStep > index || isAuthenticationComplete,
              stepNumber: currentStep !== index || isAuthenticationComplete,
              activeStepNumber: currentStep === index && !isAuthenticationComplete
            })}
          >
            {currentStep > index || isAuthenticationComplete ? <CheckMarkWhite /> : index + 1}
          </span>
          <p
            className={cx({
              stepText: currentStep !== index || isAuthenticationComplete,
              activeStepText: currentStep === index && !isAuthenticationComplete
            })}
          >
            {step.title}
          </p>
          {index < steps.length - 1 && <div data-testid="breadcrumb-separator" className={styles.separator} />}
        </div>
      ))}
    </div>
  )
}
