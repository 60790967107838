import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import { Button } from '@percent/lemonade'
import { ReactComponent as Letterbox } from '@percent/cause-dashboard/common/assets/images/letterbox.svg'
import { VERIFICATION_DATA } from '@percent/cause-dashboard/constants/emailVerificationData'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import styles from './EmailVerificationDetail.module.scss'
import { CausesPortalLogoAndText } from '@percent/cause-dashboard/common/components/causesPortalLogoText/CausesPortalLogoText'

type EmailVerificationDetailProps = {
  handleResendEmailSubmit: () => void
  loading: boolean
  success: boolean
}

export function EmailVerificationDetail({ handleResendEmailSubmit, loading, success }: EmailVerificationDetailProps) {
  const { t } = useTranslation()

  return (
    <Grid className={styles.container}>
      <Grid className={styles.resendEmailSection}>
        <Grid className={styles.percentLogoGrid} container justifyContent="center">
          <CausesPortalLogoAndText />
        </Grid>
        <Grid className={styles.percentLetterboxGrid} container justifyContent="center">
          <Letterbox />
        </Grid>
        <Typography variant="h4" className={styles.headerTitle}>
          {t(VERIFICATION_DATA.title)}
        </Typography>
        <Typography className={styles.verificationDetailGrid}>
          {t('emailVerification.verificationDetail.check')}
        </Typography>
        <Grid className={styles.buttonContainer}>
          <Button
            size="large"
            data-testid="resend-email-button"
            disabled={loading || success}
            onPress={handleResendEmailSubmit}
          >
            {t(VERIFICATION_DATA.mainButtonTitle)}
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  )
}
