export enum MarketplaceProgrammeVisitedSource {
  Banner = 'Banner',
  Card = 'Card',
  Popup = 'Popup'
}

export enum ProgrammeViewEventKind {
  BannerShown = 'Banner shown',
  ModalShown = 'Calendly modal shown',
  PopupShown = 'Pop up shown',
  PopupDismissed = 'Pop up dismissed'
}

export enum PageViewSource {
  AppStore = 'App store',
  Profile = 'Profile page'
}

export enum ProgramGenericEventKind {
  ButtonClicked = 'Button clicked',
  Viewed = 'Viewed'
}

export const metadataKeys = ['originCategory', 'offerName', 'offerType', 'hasBuyNowOption'] as const

export type MarketingMetadataKey = typeof metadataKeys[number]

export type MarketingMetadataKeyStore = MarketingMetadataKey | 'buttonText'
