import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import { useAuthState, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { MilestonesTable } from './milestonesTable/MilestonesTable'
import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { CreateItemModal } from './modals/createItem/CreateItemModal'
import { useToast } from '@percent/cause-dashboard/containers/ToastContext'
import { SuccessModal } from '@percent/cause-dashboard/common/components/modal/SuccessModal/SuccessModal'

enum SaveType {
  Undefined = 'undefined',
  Failed = 'failed',
  Succeeded = 'succeeded'
}

const unSupportedLocation = ['GBR', 'USA', 'AUS']

export function MilestonesContainer() {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [{ data, isLoading, errorMessage, totalResults }, { refresh, refreshToFirstPage, nextPage, previousPage }] =
    useQueryList(milestonesService.getMoneyMilestones(user?.organisationId as string), {})
  const { t } = useTranslation()
  const [saveSuccess, setSaveSuccess] = useState(SaveType.Undefined)
  const [open, setOpen] = useState(false)
  const addToast = useToast()
  const [dialogContainerTitle, setDialogContainerTitle] = useState(`${t('dialog.addMilestone.title')}`)
  const handleDialogOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
    setSaveSuccess(SaveType.Undefined)
    setDialogContainerTitle(`${t('dialog.addMilestone.title')}`)
  }
  const handleSave = (success: boolean) => {
    if (success) {
      setSaveSuccess(SaveType.Succeeded)
      setDialogContainerTitle('')
      refreshToFirstPage()
    } else {
      setSaveSuccess(SaveType.Failed)
      addToast(`${t('toast.milestoneApiFail')}`, 'error')
    }
  }

  const handleEditSave = (success: boolean) => {
    if (success) {
      addToast(`${t('toast.milestoneEditSuccess')}`, 'success')
      refresh()
    } else {
      addToast(`${t('toast.milestoneApiFail')}`, 'error')
    }
  }

  const noMilestoneInLocation = !unSupportedLocation.includes(organisation?.countryCode || '')

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <MilestonesTable
        data={data}
        isLoading={isLoading}
        errorMessage={errorMessage}
        totalResults={totalResults}
        addItem={handleDialogOpen}
        editSave={handleEditSave}
        refresh={refresh}
        nextPage={nextPage}
        previousPage={previousPage}
        noMilestoneInLocation={noMilestoneInLocation}
        currencyCode={organisation?.currencyCode}
      />
      <ModalContainer openModal={open} onClose={handleDialogClose} headerTitle={dialogContainerTitle}>
        {saveSuccess === SaveType.Succeeded ? (
          <SuccessModal title={t('dialog.addMilestone.success')} onClose={handleDialogClose} />
        ) : (
          <CreateItemModal cancel={handleDialogClose} save={handleSave} />
        )}
      </ModalContainer>
    </Grid>
  )
}
