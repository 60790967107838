import React from 'react'
import { FormikProvider, useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { AuthView } from '../auth/AuthView'

import { Alert, Button, FormField, PasswordCriteria, Spacer, TextInput, usePasswordCriteria } from '@percent/lemonade'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { FieldError } from '@percent/cause-dashboard/common/components/field/fieldError/FieldError'
import { passwordRequirementRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { useDefaultPasswordCriteria } from '@percent/cause-dashboard/common/hooks/useDefaultPasswordCriteria/useDefaultPasswordCriteria'

export function SetPassword() {
  const { authService } = useServices()
  const { search } = useLocation()
  const { push } = useHistory()
  const hash = new URLSearchParams(search).get('userHash')
  const [{ success, error, errorMessage, isLoading }, { apiFunc: setPassword }] = useMutation(authService.setPassword)
  const { t } = useTranslation()
  const history = useHistory()
  const defaultPasswordCriteria = useDefaultPasswordCriteria()

  const formik = useFormik({
    initialValues: {
      newPassword: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        newPassword: Yup.string()
          .min(8, t('errorMessage.minimum8Characters'))
          .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
          .max(64)
          .required('Required')
      }),
    onSubmit: ({ newPassword }: { newPassword: string }) => {
      if (!hash) {
        history.push('/signin')
      } else {
        setPassword({ password: newPassword, hash })
      }
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik
  const shouldShowPasswordCriteria = values.newPassword.length > 0 || touched.newPassword

  const { isValid } = usePasswordCriteria(defaultPasswordCriteria, values.newPassword)

  const hashInvalid = !hash || error?.code === 'auth/link_invalid'

  if (success) {
    return (
      <AuthView heading={t('typography.passwordSet')}>
        <Button stretch size="large" type="button" data-testid="log-in-button" onPress={() => push('/signin')}>
          {t('form.signIn')}
        </Button>
      </AuthView>
    )
  }

  if (hashInvalid) {
    return (
      <AuthView heading={t('typography.setPassword')}>
        <Alert variant={'error'} title={t('errorMessage.somethingWentWrong')}>
          {t('typography.newPasswordInvalidHash')}{' '}
          <a href="/forgot-password">{t('typography.newPasswordInvalidHashLink')}</a>
        </Alert>
      </AuthView>
    )
  }

  return (
    <AuthView heading={t('typography.setPassword')} handleSubmit={handleSubmit}>
      {!hashInvalid && (
        <>
          <FormikProvider value={formik}>
            <FormField
              label={t('typography.newPassword')}
              status={touched.newPassword && errors.newPassword ? 'danger' : 'default'}
              data-testid="password"
            >
              <TextInput
                type="password"
                name="newPassword"
                placeholder={t('typography.newPasswordPlaceholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                autoComplete={'new-password'}
              />
            </FormField>
            <Spacer size={12} axis="vertical" />
            <PasswordCriteria
              password={values.newPassword}
              visible={shouldShowPasswordCriteria}
              passwordCriteria={defaultPasswordCriteria}
            />
          </FormikProvider>
          {errorMessage && <FieldError error={errorMessage} />}
          <Spacer size={10} axis="vertical" />
          <div>
            <Button
              stretch
              size="large"
              type="submit"
              loading={isLoading}
              disabled={!isValid}
              data-testid="auth-active-button"
            >
              {t('typography.newPasswordButton')}
            </Button>
          </div>
        </>
      )}
    </AuthView>
  )
}
