import styles from './AgentVerificationDocumentsStatus.module.scss'
import { ReactComponent as SuccessIcon } from '@percent/cause-dashboard/common/assets/images/success-icon.svg'
import { ReactComponent as ErrorIcon } from '@percent/cause-dashboard/common/assets/images/error-icon.svg'

import {
  AVDocumentsPartner,
  AVDocumentsStatus,
  AgentVerificationDocumentsMessages,
  AgentVerificationDocumentsStatusProps
} from './AgentVerificationDocumentsStatus.types'
import { useTranslation } from 'react-i18next'
import { AgentVerificationStatus } from '@percent/cause-dashboard/services/agentVerification/AgentVerification.types'
import { AnimatedContainer, Button } from '@percent/lemonade'
import { ZENDESK_URL } from '../AgentVerificationDocuments'
import { useHistory } from 'react-router-dom'
import { getSignUpUrl } from '@percent/cause-dashboard/common/utility/utility'
import { SignUpReason } from '@percent/cause-dashboard/common/hooks/useSignUpAttribution/useSignUpAttribution'

const documentsMessages: AgentVerificationDocumentsMessages = {
  expired: {
    title: 'agentVerificationDocuments.status.expired.title',
    description: 'agentVerificationDocuments.status.expired.description'
  },
  processed: {
    title: 'agentVerificationDocuments.status.processed.title',
    description: 'agentVerificationDocuments.status.processed.description'
  },
  submitted: {
    title: 'agentVerificationDocuments.status.submitted.title',
    description: 'agentVerificationDocuments.status.submitted.description',
    claimDescription: 'agentVerificationDocuments.status.submitted.claimDescription'
  },
  pending: {
    title: 'agentVerificationDocuments.status.pending.title',
    description: 'agentVerificationDocuments.status.pending.description'
  }
}

export const AgentVerificationDocumentsStatus = ({
  status,
  agentVerificationStatus,
  partnerName,
  email,
  organisationId
}: Readonly<AgentVerificationDocumentsStatusProps>) => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const { title, description, claimDescription } = documentsMessages[status]
  const isExpired = status === AVDocumentsStatus.EXPIRED
  const isRejected = agentVerificationStatus === AgentVerificationStatus.REJECTED
  const isGooglePartner = partnerName?.toLowerCase() === AVDocumentsPartner.GOOGLE
  const isClaimSubmission = status === AVDocumentsStatus.SUBMITTED && partnerName === AVDocumentsPartner.CLAIM

  const handleSignUpClick = () => {
    const url = getSignUpUrl({
      organizationId: organisationId,
      email,
      signUpReason: SignUpReason.AV_DOCUMENT_UPLOAD
      // TODO: get partnerId
    })
    push(url)
  }

  return (
    <div className={styles.documentsStatusContainer}>
      {!isRejected && (
        <AnimatedContainer>
          {isExpired ? <ErrorIcon data-testid="error-icon" /> : <SuccessIcon data-testid="success-icon" />}
        </AnimatedContainer>
      )}

      <h5>{t(title)}</h5>
      <p>
        {t(isClaimSubmission ? claimDescription! : description, { partnerName })}
        {isExpired && (
          <a href={ZENDESK_URL} target="_blank" rel="noreferrer">
            poweredbypercent.zendesk.com
          </a>
        )}
      </p>

      {isGooglePartner && !isExpired && !isRejected && (
        <div className={styles.googleSetupContainer}>
          <h6>{t('agentVerificationDocuments.googleCTA.title')}</h6>
          <p>{t('agentVerificationDocuments.googleCTA.description')}</p>
          <Button onPress={handleSignUpClick}>{t('typography.experiments.ctaButton')}</Button>
        </div>
      )}
    </div>
  )
}
