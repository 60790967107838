import React from 'react'
import { Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

type ErrorViewProps = {
  errorMessage: string
  errorMessageSize?:
    | 'h4'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'srOnly'
    | undefined
}

export function ErrorView({ errorMessage, errorMessageSize = 'h6' }: ErrorViewProps) {
  const { t } = useTranslation()

  return (
    <Typography variant={errorMessageSize} color="primary" align="center">
      {`${errorMessage} ${t('typography.pleaseRefresh')}`}
    </Typography>
  )
}
