import React from 'react'

import { SuccessModalContainer } from './SuccessModalContainer'
import styles from './SuccessModalContainer.module.scss'
import { ReactComponent as Tick } from '@percent/cause-dashboard/common/assets/images/modal/tick.svg'
import { ReactComponent as InnerGreenCircle } from '@percent/cause-dashboard/common/assets/images/modal/inner-green-circle.svg'
import { ReactComponent as OuterGreenCircle } from '@percent/cause-dashboard/common/assets/images/modal/outer-green-circle.svg'

type SuccessModalProps = {
  title: string
  onClose: () => void
  subTitle?: string
}

export function SuccessModal({ title, onClose, subTitle }: SuccessModalProps) {
  return (
    <SuccessModalContainer title={title} subTitle={subTitle} onClose={onClose}>
      <OuterGreenCircle className={styles.outerGreenCircle} />
      <InnerGreenCircle className={styles.innerGreenCircle} />
      <Tick className={styles.tick} />
    </SuccessModalContainer>
  )
}
