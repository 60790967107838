import React from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import { AuthView } from '../auth/AuthView'

import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './ForgotPassword.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { emailRegex } from '@percent/utility'

export function ForgotPassword() {
  const { authService } = useServices()
  const [{ success, errorMessage }, { apiFunc: forgotPassword }] = useMutation(authService.forgotPassword)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().required('Required').matches(emailRegex, t('errorMessage.validEmail'))
      }),
    onSubmit: ({ email }) => {
      forgotPassword(email)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  if (success) {
    return (
      <AuthView heading={t('typography.passwordResetSuccess')}>
        <Typography variant="body1" className={styles.passwordResetText}>
          {t('typography.passwordReset')}
        </Typography>
      </AuthView>
    )
  }

  return (
    <AuthView causesPortalLogo heading={t('form.forgotPassword')} handleSubmit={handleSubmit}>
      <Typography className={styles.passwordText} variant="body2">
        {t('typography.passwordResetLink')}
      </Typography>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.emailAddress')}
          status={touched.email && errors.email ? 'danger' : 'default'}
          statusMessage={errors.email}
          data-testid="email"
        >
          <TextInput
            name="email"
            placeholder={t('form.enterEmailAddress')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </FormField>
      </FormikProvider>
      <Spacer size={5} axis="vertical" />
      {errorMessage && <Feedback variant="critical">{errorMessage}</Feedback>}
      <Spacer size={5} axis="vertical" />
      <Button stretch size="large" type="submit" data-testid="auth-active-button">
        {t('button.sendEmail')}
      </Button>
      <Typography variant="body2" className={styles.footerText}>
        {t('typography.lookingForLogin')}{' '}
        <span className={styles.clickHere}>
          <Link to="/signin">{t('typography.clickHere')}</Link>
        </span>
      </Typography>
    </AuthView>
  )
}
