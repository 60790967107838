import { Experiment } from '@percent/cause-dashboard/common/hooks/useExperiment/experiments.types'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { cloneDeep } from 'lodash'

interface ITrackEventParams<T> {
  eventName: T
  opts?: Record<string, string>
}

export const useExperiment = <T extends string>(experiment: Experiment<T>, partnerId: string) => {
  const featureFlag = experiment.featureFlag
  const {
    _meta: { isReady: ffServiceReady },
    ...flags
  } = useFeatureFlag()
  const [canPerformExperiment, setCanPerformExperiment] = useState(false)
  const { track } = useCausesPortalAnalytics()

  const triggered = useRef<string[]>([])

  const [trackingQueue, setTrackingQueue] = useState<ITrackEventParams<keyof typeof experiment.events>[]>([])

  const getExperimentHash = (eventName: string) => {
    const plainText = `${experiment.name}-${eventName}`
    return btoa(plainText).split('').reverse().join('')
  }

  const isFeatureEnabled = useCallback(() => {
    return (
      !!featureFlag &&
      Boolean(
        Object.keys(flags).includes(featureFlag?.toString()) && flags[featureFlag as unknown as keyof typeof flags]
      )
    )
  }, [featureFlag, flags])

  const validPartnerId = useCallback(() => {
    const excludedPartnerIds = experiment.partnerIdCondition.type === 'exclude' ? experiment.partnerIdCondition.ids : []
    const includedPartnerIds = experiment.partnerIdCondition.type === 'include' ? experiment.partnerIdCondition.ids : []

    if (excludedPartnerIds?.includes(partnerId)) {
      return false
    }
    if (includedPartnerIds?.length && !includedPartnerIds.includes(partnerId)) {
      return false
    }
    return true
  }, [experiment.partnerIdCondition.ids, experiment.partnerIdCondition.type, partnerId])

  const trackEvent = useCallback(
    (eventName: keyof typeof experiment.events, opts?: Record<string, string>) => {
      if (!ffServiceReady) {
        setTrackingQueue([...trackingQueue, { eventName, opts }])
        return false
      }

      const featureFlagIsEnabledOrNotRequired = typeof featureFlag === 'undefined' || isFeatureEnabled()

      if (!(featureFlagIsEnabledOrNotRequired && validPartnerId())) {
        return false
      }

      void track(experiment.events[eventName].name, { experiment: experiment.name, ...opts })

      return true
    },
    [experiment, ffServiceReady, isFeatureEnabled, track, trackingQueue, validPartnerId]
  )

  const trackEventOnceThisSession = (eventName: T, opts?: Record<string, string>) => {
    if (!partnerId) {
      return
    }

    const eventNameKey = getExperimentHash(eventName)
    const hasBeenTriggered =
      triggered.current?.includes(eventNameKey) || sessionStorage.getItem(eventNameKey) === 'true'

    if (hasBeenTriggered) {
      return false
    }

    triggered.current = [...triggered.current, eventNameKey]
    sessionStorage.setItem(eventNameKey, 'true')

    trackEvent(eventName, opts)
    return true
  }

  useEffect(() => {
    setCanPerformExperiment(isFeatureEnabled() && validPartnerId())
  }, [isFeatureEnabled, validPartnerId])

  // Consume tracking queue after feature flag service is ready
  useEffect(() => {
    if (!trackingQueue.length || !ffServiceReady) {
      return
    }

    let trackingQueueDeepCopy = cloneDeep(trackingQueue)
    for (const { eventName, opts } of trackingQueue) {
      trackEvent(eventName, opts)
      trackingQueueDeepCopy = trackingQueueDeepCopy.filter(item => item.eventName !== eventName)
    }
    setTrackingQueue(trackingQueueDeepCopy)
  }, [ffServiceReady, trackEvent, trackingQueue])

  return {
    events: experiment.events,
    trackEventOnceThisSession,
    trackEvent,
    canPerformExperiment
  }
}
