import { Grid } from '@material-ui/core'
import styles from './DonationFormPage.module.scss'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { TitleCard } from '@percent/cause-dashboard/common/components/titleCard/TitleCard'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Code } from '@percent/cause-dashboard/common/components/codeBlock/Code'
import { config } from '@percent/cause-dashboard/config'

export const DonationFormPage = () => {
  const { t } = useTranslation()
  const {
    authState: { user }
  } = useAuthState()

  // Hello future me: Change this to an ENV variable, and update to real domain when terraform done
  const loaderScript = `<script defer
        src="${config.urls.donationWidget}"></script>`
  const buttonScript = `<div id="pbp-donation-widget"
     data-link-id="${config.ids.donationLink}"
     data-organisation-id="${user?.organisationId}" />`

  return (
    <Grid container spacing={4}>
      <TitleCard title={t('card.donationForm.title')} description={t('card.donationForm.description')} size={7}>
        <div className={styles.container}>
          <Typography variant="h4" className={styles.mainHeading}>
            {t('donationForm.popupHeading')}
          </Typography>
          <Typography className={styles.description}>{t('donationForm.popupDescription')}</Typography>
          <Typography variant="h5" className={styles.instructionsHeading}>
            {t('donationForm.loadLibraryStep.heading')}
          </Typography>
          <Typography className={styles.description}>
            {t('donationForm.loadLibraryStep.description', { head: '<head>' })}
          </Typography>
          <Code code={loaderScript} language="html" wordWrap={true} />
          <Typography variant="h5" className={styles.instructionsHeading}>
            {t('donationForm.addButtonStep.heading')}
          </Typography>
          <Typography className={styles.description}>{t('donationForm.addButtonStep.description')}</Typography>
          <Code code={buttonScript} language="html" wordWrap={true} />
        </div>
      </TitleCard>
    </Grid>
  )
}
