import { Heading, Spacer, Text } from '@percent/lemonade'
import { MultiStepFormHeaderProps } from './MultiStepFormHeader.types'
import styles from './MultiStepFormHeader.module.scss'
import { CausesPortalLogoAndText } from '../../causesPortalLogoText/CausesPortalLogoText'

export function MultiStepFormHeader({ title, subtitle }: MultiStepFormHeaderProps) {
  return (
    <>
      <CausesPortalLogoAndText />
      {title && (
        <div className={styles.title}>
          <Spacer size={8} axis="vertical" />
          <Heading level="h4">{title}</Heading>
        </div>
      )}
      {subtitle && (
        <>
          <Spacer size={4} axis="vertical" />
          <Text align="left">{subtitle}</Text>
        </>
      )}
    </>
  )
}
