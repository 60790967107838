import { FormStep } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm.types'
import { passwordRequirementRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { config } from '@percent/cause-dashboard/config'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import * as Yup from 'yup'
import styles from './SetPasswordToJoinOrganisation.module.scss'
import { Box, Grid } from '@mui/material'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { MultiStepForm } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm'
import { FormikValues } from 'formik'
import { OrganisationCard } from '@percent/cause-dashboard/common/components/organisationCard/OrganisationCard'
import { SignUpConfirmation } from './SignUpConfirmation'

type SetPasswordToJoinOrganisationProps = {
  organisationData: {
    inviterEmail: string
    name: string
    logo?: string
    website?: string
  }
  setPassword: (password: string) => Promise<void>
  errorMessage?: string
  isAccountActive: boolean
  isFormSubmitted: boolean
  setIsFormSubmitted: (isFormSubmitted: boolean) => void
}

export function SetPasswordToJoinOrganisation(props: Readonly<SetPasswordToJoinOrganisationProps>) {
  const { organisationData, setPassword, errorMessage, isAccountActive, isFormSubmitted, setIsFormSubmitted } = props
  const { t } = useTranslation()

  const formSteps = useMemo(() => {
    const passwordStepValidationSchema = Yup.object().shape({
      password: Yup.string()
        .min(8, t('form.minLength', { name: 'Password', length: 8 }))
        .max(64, t('form.maxLength', { name: 'Password', length: 64 }))
        .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
        .required(t('form.isRequired', { name: 'Password' })),
      agreement: Yup.boolean().oneOf([true], t('errorMessage.acceptTerms')).required(t('errorMessage.acceptTerms'))
    })

    const formSteps: FormStep[] = [
      {
        stepTitle: t('invite.accept.title'),
        stepSubtitle: (
          <>
            <span className={styles.boldText}>{organisationData.inviterEmail}</span>
            {t('invite.accept.subtitle')}
          </>
        ),
        fields: [
          {
            name: 'organisationView',
            type: 'customView',
            customView: (
              <Box className={styles.organisationCardWrapper}>
                <OrganisationCard
                  name={organisationData.name}
                  logo={organisationData.logo}
                  website={organisationData.website}
                />
              </Box>
            )
          }
        ],
        validationSchema: Yup.object().shape({}),
        buttonTopText: t('invite.accept.buttonTopText'),
        buttonText: t('invite.accept.buttonText')
      },
      {
        stepTitle: t('form.oneThing'),
        stepSubtitle: t('form.passwordSubtitle'),
        fields: [
          {
            name: 'password',
            label: t('form.password'),
            placeholder: t('form.enterPassword'),
            type: 'password',
            description: t('form.passwordDescription')
          },
          {
            name: 'agreement',
            checkboxLabel: (
              <span className={styles.checkboxLabel}>
                {t('typography.iAcceptThe')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.terms}>
                  {t('typography.termsOfService')}
                </a>{' '}
                {t('typography.and')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.privacyAndPolicy}>
                  {t('typography.privacyPolicy')}
                </a>
              </span>
            ),
            type: 'checkbox'
          }
        ],
        validationSchema: passwordStepValidationSchema,
        buttonText: t('form.signUp')
      }
    ]

    return formSteps
  }, [t, organisationData])

  const onSubmit = async (values: FormikValues) => {
    await setPassword(values.password)
    setIsFormSubmitted(true)
  }

  return (
    <Grid container spacing={0} className={styles.container} data-testid="password-screens">
      {isFormSubmitted && isAccountActive ? (
        <Grid
          item
          sm={12}
          md={6}
          className={`${styles.leftSection} ${styles.topPadding}`}
          data-testid="confirmation-screen"
        >
          <SignUpConfirmation />
          <Footer noTermsAndPrivacyPolicy />
        </Grid>
      ) : (
        <Grid item sm={12} md={6} className={styles.leftSection} data-testid="set-password-screen">
          <Box className={styles.formWrapper}>
            <MultiStepForm steps={formSteps} onSubmit={onSubmit} errorMessage={errorMessage} />
          </Box>
          <Footer noTermsAndPrivacyPolicy />
        </Grid>
      )}
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={DETAIL_BULLET_POINTS_DEFAULT} />
      </Grid>
    </Grid>
  )
}
