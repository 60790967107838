import React, { useEffect, useState } from 'react'
import { ClickAwayListener, Container, Popper } from '@material-ui/core'

import { DateRangePopperType } from '../DateRange.types'

import styles from './DateRangePopper.module.scss'
import { Button } from '@percent/lemonade'
import { PressEvent } from '@react-types/shared'
import { CustomDateRange } from '../CustomDateRange'
import { dayJS } from '@percent/cause-dashboard/common/utility/date'
import { useTranslation } from 'react-i18next'

export function DateRangePopper({
  open,
  anchorEl,
  placement,
  setOpen,
  setQueryParams,
  queryParams
}: DateRangePopperType) {
  const { t } = useTranslation()
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: dayJS(queryParams.startDate).utc().toDate(),
      endDate: dayJS(queryParams.endDate).endOf('day').toDate(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    setSelectionRange([
      {
        startDate: dayJS(queryParams.startDate).utc().toDate(),
        endDate: dayJS(queryParams.endDate).endOf('day').toDate(),
        key: 'selection'
      }
    ])
  }, [queryParams])

  const handleDateChange = (event: PressEvent) => {
    setOpen(false)
    setQueryParams?.({
      startDate: dayJS(selectionRange[0]?.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: dayJS(selectionRange[0]?.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    })
  }

  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} className={styles.popperContainer} transition>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Container className={styles.dateRangePopper}>
          <>
            <CustomDateRange
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              endDate={selectionRange[0]?.endDate}
            />
            <div>
              <Button type="submit" data-testid="apply-date" onPress={(e: PressEvent) => handleDateChange(e)}>
                {t('button.apply')}
              </Button>
            </div>
          </>
        </Container>
      </ClickAwayListener>
    </Popper>
  )
}
