import { productOffers } from '@percent/cause-dashboard/app/productOffers/data/productOffers'
import { ProductOffer } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { percentServices } from '@percent/cause-dashboard/app/productOffers/data/percentServices'

export interface IProductCategory {
  name: string
  description: string
  offers: ProductOffer[]
  featured?: boolean
  order?: (offers: ProductOffer[]) => ProductOffer[]
}

export function fisherYatesRandomisedOffers(offers: ProductOffer[]): ProductOffer[] {
  const array = [...offers]

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

const categories: IProductCategory[] = [
  {
    name: 'Top tools & support',
    description: 'In a hurry? Discover & get started with our top tools and integration support',
    offers: [
      percentServices.googleWorkspaceSupport,
      percentServices.googleAdsSupport,
      productOffers.asana,
      productOffers.openAi
    ],
    featured: true
  },
  {
    name: '🔥 Coming soon',
    description: 'Be the first to experience our exciting new features by joining our waitlists today',
    offers: [
      productOffers.grantFinder,
      productOffers.causeCommunity,
      productOffers.HRandPayroll,
      productOffers.donationProcessing,
      productOffers.volunteerFinder
    ],
    order: fisherYatesRandomisedOffers
  },
  {
    name: 'Fundraising',
    description: 'Donation processing',
    offers: [percentServices.fundraisingAndGrants, productOffers.linkedInFundraise, productOffers.givebutter]
  },
  {
    name: 'Marketing',
    description: 'Grow your marketing, social media and advertising',
    offers: [
      percentServices.marketingSupport,
      percentServices.websiteCmsSupport,
      percentServices.googleAdsSupport,
      percentServices.googleAdsOptimizationPing,
      productOffers.google,
      productOffers.hootsuite,
      productOffers.twilio,
      productOffers.eventbrite,
      productOffers.freshworks,
      productOffers.hubspot,
      productOffers.canva
    ]
  },
  {
    name: 'Project management',
    description: 'Manage all your projects and tasks',
    offers: [productOffers.asana, productOffers.atlassian]
  },
  {
    name: 'Legal and accounting',
    description: 'Keep on top of your legal and financial obligations',
    offers: [
      percentServices.accountingAndFinancialServices,
      productOffers.sage,
      productOffers.xero,
      productOffers.pandaDoc,
      productOffers.quickBooks,
      productOffers.airslate
    ]
  },
  {
    name: 'Security',
    description: 'Keep your software safe and secure',
    offers: [
      percentServices.managedItSupport,
      productOffers.auth0,
      productOffers.okta,
      productOffers.newRelic,
      productOffers.pagerDuty,
      productOffers.splunk,
      productOffers.nordlayer
    ]
  },

  {
    name: 'Recruitment',
    description: 'Find the best employees and volunteers',
    offers: [
      productOffers.breezy,
      productOffers.linkedInHire,
      productOffers.teamtailor,
      productOffers.toptal,
      productOffers.fiverr,
      productOffers.workable
    ]
  },
  {
    name: 'Productivity tools',
    description: 'Increase your team’s operational efficiency',
    offers: [productOffers.docusign, productOffers.box]
  }
]

export const categorizedProducts = categories.map((category, index) => ({
  id: index,
  ...category,
  offers: category.order ? category.order(category.offers) : category.offers
})) as Array<{ id: number } & IProductCategory>
