import React from 'react'

import styles from './Code.module.scss'
import { CodeProps } from './Code.types'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import nord from 'react-syntax-highlighter/dist/cjs/styles/prism/nord'
import { Copy } from '@percent/lemonade'

export function Code({ code, language, lineNumbers = false, wordWrap = false }: CodeProps) {
  return (
    <div className={styles.wrapper}>
      <SyntaxHighlighter
        customStyle={{
          borderRadius: '8px',
          fontSize: '14px',
          padding: '24px',
          paddingRight: '48px',
          margin: 0
        }}
        language={language}
        showInlineLineNumbers={lineNumbers}
        wrapLongLines={wordWrap}
        style={nord}
      >
        {code}
      </SyntaxHighlighter>
      <Copy text={code} className={styles.copy} />
    </div>
  )
}
