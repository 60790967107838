import React, { useState } from 'react'
import { Container } from '@material-ui/core'

import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { NavigationBar } from '@percent/cause-dashboard/common/components/NavigationBar'
import { ClaimSearchHeader } from './ClaimSearchHeader/ClaimSearchHeader'
import { ClaimSearchResults } from './ClaimSearchResults/ClaimSearchResults'
import styles from './ClaimContainer.module.scss'
import { defaultCountryCode } from '@percent/cause-dashboard/constants/countryData'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import logos from '@percent/cause-dashboard/common/assets/images/logos.png'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { SignUpEventName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/causeDashboardAnalytics.types'

export function ClaimContainer() {
  const { t } = useTranslation()

  const { track } = useCausesPortalAnalytics()

  const [claimResults, setClaimResults] = useState({
    selectedCharityCountryCode: defaultCountryCode,
    selectedCharityName: ''
  })

  const handleSearchClick = (countryCode: string, query: string) => {
    setClaimResults({
      selectedCharityCountryCode: countryCode,
      selectedCharityName: query
    })

    track(SignUpEventName.SEARCH_FOR_NONPROFIT, {
      organisationName: query
    })
  }

  return (
    <>
      <NavigationBar />
      <Container maxWidth="md" className={styles.heroContainer}>
        <Box className={styles.hero}>
          <Typography variant="h3" color="secondary" className={styles.heroTitle}>
            {t('typography.heroTitle')}
          </Typography>
          <Typography color="secondary" className={styles.heroDescription}>
            {t('typography.heroDescription')}
          </Typography>
        </Box>
        <img className={styles.heroLogos} alt="logos-landing-page" src={logos} />
      </Container>
      <Box className={styles.mainContainer}>
        <Container className={styles.headerTableContainer} maxWidth="md">
          <ClaimSearchHeader onSearchClick={handleSearchClick} />
          <ClaimSearchResults claimResults={claimResults} />
        </Container>
        <Container maxWidth="md">
          <Footer />
        </Container>
      </Box>
    </>
  )
}
