import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES, PERCENT_SERVICE_STRIPE_URL_PING } from './appStoreUrls'

export const percentServicesCatalog = {
  marketingSupport: {
    type: ProductOfferType.PercentService,
    name: 'Marketing support',
    description: 'Expert guidance to streamline your marketing, social, and advertising needs',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  googleAdsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Ad Grants Support',
    description: 'Create, manage, and optimize your Ad Grants with digital marketing specialists',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  managedItSupport: {
    type: ProductOfferType.PercentService,
    name: 'Managed IT support',
    description: 'Ongoing IT support for software planning and implementation',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  accountingAndFinancialServices: {
    type: ProductOfferType.PercentService,
    name: 'Accounting and financial services',
    description: 'Expert accounting, bookkeeping and financial management',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  websiteCmsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Website & CMS support',
    description: 'Engage donors with expertly designed and managed websites',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  googleWorkspaceSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Workspace support',
    description: 'Get help with set up and ongoing management of Workspace',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    expressCheckout: true,
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  fundraisingAndGrants: {
    type: ProductOfferType.PercentService,
    name: 'Fundraising and grants',
    description: 'Expert advice to maximise fundraising including grant writing services',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  googleAdsOptimizationPing: {
    type: ProductOfferType.PercentService,
    name: 'PING + Assistant',
    description:
      'Subscribe for a weekly audit of your Google Ad Grants account with daily automated optimizations. Only $79 per month',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Ping.png'),
    primaryButton: {
      linkType: 'external',
      link: PERCENT_SERVICE_STRIPE_URL_PING,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  }
} as const

type PercentServicesKeys = keyof typeof percentServicesCatalog

export const percentServices = percentServicesCatalog as Record<PercentServicesKeys, ProductOffer>
