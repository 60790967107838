import { Grid } from '@mui/material'
import styles from './RequestToJoinOrgForm.module.scss'
import { Text, FormField, TextInput, Button, AcknowledgeModal as AcknowledgeView } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { ZENDESK_TICKET_LINK } from '@percent/cause-dashboard/constants/zendesk'
import { useLocation } from 'react-router-dom'
import { createRef, useMemo } from 'react'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { MultiStepFormHeader as FormHeader } from '../../../../../common/components/multiStepForm/multiStepFormHeader/MultiStepFormHeader'
import { CausesPortalLogoAndText } from '@percent/cause-dashboard/common/components/causesPortalLogoText/CausesPortalLogoText'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import ReCAPTCHA from 'react-google-recaptcha'
import { config } from '@percent/cause-dashboard/config'

enum ModalState {
  FORM,
  SUCCESS,
  FAILURE
}

export function RequestToJoinOrgForm() {
  const { t } = useTranslation()
  const { inviteService } = useServices()
  const { requestToJoinCaptcha } = useFeatureFlag()
  const recaptchaRef = createRef<ReCAPTCHA>()

  const [{ isLoading: isMutating, error, data }, { apiFunc: requestToJoinOrganisation }] = useMutation(
    inviteService.requestToJoinOrganisation
  )
  const { search } = useLocation()

  const { organisationId, organisationName } = useMemo(() => {
    const params = new URLSearchParams(search)
    return {
      organisationId: params.get('id') ?? '',
      organisationName: params.get('name') ?? ''
    }
  }, [search])

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async values => {
      let token: string | null | undefined

      if (requestToJoinCaptcha) {
        token = await recaptchaRef?.current?.executeAsync()
      }
      requestToJoinOrganisation({ email: values.email, organisationId, token })
    },
    validationSchema: () =>
      object().shape({
        email: string().required(t('errorMessage.required')).email(t('typography.userManagement.invalidEmail'))
      })
  })

  const { touched, errors, handleChange, handleSubmit, handleBlur, values } = formik

  const modalState = error ? ModalState.FAILURE : data ? ModalState.SUCCESS : ModalState.FORM

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item sm={12} md={6} className={styles.requestToJoinSection}>
        <div className={styles.formWrapper}>
          {modalState === ModalState.FORM && (
            <form onSubmit={handleSubmit} data-testid="sent-invite-form">
              <FormikProvider value={formik}>
                <FormHeader
                  title={t('typography.requestToJoin.modal.title')}
                  subtitle={
                    <Text>
                      {t('typography.requestToJoin.modal.subtitle1')}
                      <b>{organisationName}</b>
                      {t('typography.requestToJoin.modal.subtitle2')}
                    </Text>
                  }
                />
                <div className={styles.emailInputWrapper}>
                  <FormField
                    label={t('form.emailAddress')}
                    status={touched.email && errors.email ? 'danger' : 'default'}
                    statusMessage={errors.email}
                    data-testid="request-invite-email-input"
                  >
                    <TextInput
                      name="email"
                      placeholder={t('form.enterEmailAddress')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      required={true}
                    />
                  </FormField>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    size="large"
                    data-testid={'send-invite-btn'}
                    loading={isMutating}
                    disabled={!formik.isValid || !formik.dirty}
                    stretch
                  >
                    {t('button.requestToJoin')}
                  </Button>
                </div>
              </FormikProvider>
              {requestToJoinCaptcha && (
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.keys.googleCaptcha} />
              )}
            </form>
          )}
          {modalState === ModalState.FAILURE && (
            <>
              <CausesPortalLogoAndText />
              <AcknowledgeView
                result="negative"
                title={t('typography.requestToJoin.failureModal.title')}
                viewTestId="request-to-join-failure-view"
              >
                <Text align="left">
                  {t('typography.requestToJoin.failureModal.subtitle')}{' '}
                  <a href={ZENDESK_TICKET_LINK}>{t('claims.organizationAlreadyClaimed.button')}</a>
                </Text>
              </AcknowledgeView>
            </>
          )}
          {modalState === ModalState.SUCCESS && (
            <>
              <CausesPortalLogoAndText />
              <AcknowledgeView
                result="positive"
                title={t('typography.requestToJoin.successModal.title')}
                viewTestId="request-to-join-success-view"
              >
                <Text align="left">{t('typography.requestToJoin.successModal.subtitle')} </Text>
              </AcknowledgeView>
            </>
          )}
        </div>
        <Footer noTermsAndPrivacyPolicy />
      </Grid>
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={DETAIL_BULLET_POINTS_DEFAULT} />
      </Grid>
    </Grid>
  )
}
