import { Button, FormField, Modal, Select, TextInput, Alert, useToast } from '@percent/lemonade'
import { ModalHeader } from '../../../common/components/modal/Modal/ModalHeader'
import { ModalContent } from '../../../../../../libs/shared/ui-lemonade/src/components/modal-content'
import { FormikProvider, useFormik } from 'formik'
import styles from './InviteUserModal.module.css'
import { object, string } from 'yup'
import { ModalFooter } from '../../../../../../libs/shared/ui-lemonade/src/components/modal-footer'
import { useEffect } from 'react'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

interface InviteUserModalProps {
  open: boolean
  onClose: () => void
  onSuccessfulInvite: () => void
  email?: string
}

export const InviteUserModal = ({ open, onClose, onSuccessfulInvite, email }: InviteUserModalProps) => {
  const { t } = useTranslation()
  const { iamService } = useServices()
  const toast = useToast()

  const {
    authState: { organisation }
  } = useAuthState()
  const [{ data }] = useQuery(iamService.getRoles, {})
  const [{ isLoading: isMutating, error }, { apiFunc: sendInviteAPIRequest }] = useMutation(
    iamService.inviteUser,
    () => {
      onClose()
      onSuccessfulInvite()
      toast.addToast(
        t('typography.userManagement.inviteSuccess', {
          email: formik.values.email
        }),
        'success'
      )
    }
  )

  const rolesData = data?.data?.data?.roles

  const roles =
    rolesData?.map(role => {
      return { label: role.displayName, value: role.id, description: role.description }
    }) || []

  const formik = useFormik({
    initialValues: {
      email: '',
      roleId: ''
    },
    onSubmit: values => {
      sendInviteAPIRequest({
        organisationId: organisation?.id ?? '',
        email: values.email,
        roleId: values.roleId
      })
    },
    validationSchema: () =>
      object().shape({
        email: string()
          .required(t('errorMessage.required'))
          .matches(emailRegex, t('typography.userManagement.invalidEmail')),
        roleId: string()
          .required(t('errorMessage.required'))
          .oneOf(
            roles.map(role => role.value),
            t('typography.userManagement.invalidRole')
          )
      })
  })

  const { touched, errors, handleChange, handleSubmit, handleBlur, values, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (email) {
      setFieldValue('email', email)
    }
  }, [email])

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <ModalHeader onClose={onClose} headerTitle={t('typography.userManagement.inviteMember')} />
          <ModalContent>
            {error && (
              <div className={styles.alert} data-testId={'invite-error-alert'}>
                <Alert variant={'error'}>{error.message}</Alert>
              </div>
            )}
            {t('typography.userManagement.inviteDescription')}
            <div className={styles.inviteUserModalFormFields}>
              <FormField
                label={t('form.emailAddress')}
                status={touched.email && errors.email ? 'danger' : 'default'}
                statusMessage={errors.email}
                data-testid="email-input"
              >
                <TextInput
                  name="email"
                  placeholder={t('form.enterEmailAddress')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  data-testid={'email-input'}
                  value={values.email}
                />
              </FormField>
              <FormField
                label={t('typography.userManagement.role')}
                status={touched.roleId && errors.roleId ? 'danger' : 'default'}
                statusMessage={errors.roleId}
                data-testid="role"
                description={t('typography.userManagement.selectRoleDesc')}
              >
                <Select
                  name="roleId"
                  placeholder={t('typography.userManagement.selectRole')}
                  onChange={selection => {
                    setFieldValue('roleId', selection.value)
                  }}
                  data-testid={'role-select'}
                  options={roles}
                />
              </FormField>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" size="large" variant="secondary" data-testid={'cancel-btn'} onPress={onClose}>
              {t('button.cancel')}
            </Button>
            <Button
              type="submit"
              size="large"
              data-testid={'send-invite-btn'}
              loading={isMutating}
              disabled={!formik.isValid || !formik.dirty}
            >
              {t('typography.userManagement.sendInvite')}
            </Button>
          </ModalFooter>
        </FormikProvider>
      </form>
    </Modal>
  )
}
