import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useLocation } from 'react-router-dom'
import { Spinner } from '@percent/lemonade'
import { NextAction } from '@percent/cause-dashboard/services/invite/inviteService.types'
import { Box } from '@mui/material'
import { SetPasswordToJoinOrganisation } from './SetPasswordToJoinOrganisation'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useEffect, useState } from 'react'
import { InvitationStatusScreen } from './InvitationStatusScreen'
import {
  SignUpReason,
  useSignUpAttribution
} from '@percent/cause-dashboard/common/hooks/useSignUpAttribution/useSignUpAttribution'

export function AcceptInvitePage() {
  const { search } = useLocation()
  const inviteId = new URLSearchParams(search).get('inviteId') || ''
  const { inviteService } = useServices()
  const [hasInitialisedInviteRequest, setHasInitialisedInviteRequest] = useState<boolean>(false)
  const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { setSignUpAttributionManually } = useSignUpAttribution()

  const [
    { data: acceptInviteResponse, isLoading: isLoadingInvite, error: inviteError },
    { apiFunc: runAcceptInviteMutation }
  ] = useMutation(inviteService.acceptInvite)
  const [
    { data: createAccountData, isLoading: isCreatingAccount, error: createAccountError },
    { apiFunc: createAccount }
  ] = useMutation(inviteService.createAccount)

  useEffect(() => {
    if (hasInitialisedInviteRequest) {
      return
    }

    setHasInitialisedInviteRequest(true)
    runAcceptInviteMutation({
      inviteId
    })
  }, [hasInitialisedInviteRequest, inviteId, runAcceptInviteMutation])

  const setPassword = async (password: string): Promise<void> => {
    createAccount({ inviteId, password })
  }

  useEffect(() => {
    if (createAccountData?.status === 201) {
      setIsAccountCreated(true)
    }
  }, [createAccountData, isFormSubmitted, isAccountCreated])

  useEffect(() => {
    setSignUpAttributionManually(SignUpReason.INVITED)
  }, [setSignUpAttributionManually])

  const getLayout = () => {
    if (acceptInviteResponse?.data.nextAction === NextAction.CREATE_ACCOUNT) {
      const organisationData = {
        name: acceptInviteResponse?.data.data?.organisationName ?? '',
        inviterEmail: acceptInviteResponse?.data.data?.inviterEmail ?? '',
        logo: acceptInviteResponse?.data.data?.logo,
        website: acceptInviteResponse?.data.data?.organisationWebsite
      }

      return (
        <SetPasswordToJoinOrganisation
          setPassword={setPassword}
          organisationData={organisationData}
          errorMessage={createAccountError?.message}
          isAccountActive={isAccountCreated}
          isFormSubmitted={isFormSubmitted}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )
    } else if (acceptInviteResponse?.data.nextAction === NextAction.SIGN_IN) {
      return <InvitationStatusScreen hasPartnerAccount />
    } else {
      return <InvitationStatusScreen errorCode={inviteError?.code} />
    }
  }

  if (isLoadingInvite || isCreatingAccount) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xxl" />
      </Box>
    )
  }

  return <>{getLayout()}</>
}
