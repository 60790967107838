import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { AuthView } from '../auth/AuthView'

import { SET_USER_VERIFIED, selectAuthState } from '@percent/cause-dashboard/context/auth'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { useAuthDispatch, useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './AccountVerification.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'

export function AccountVerification() {
  const { authService } = useServices()
  const { search } = useLocation()
  const { push } = useHistory()
  const { authDispatch } = useAuthDispatch()
  const { t } = useTranslation()

  const hash = new URLSearchParams(search).get('userHash')
  const [loading, setLoading] = useState(true)

  const appState = useAuthState()
  const authState = selectAuthState(appState.authState)

  const [{ success, errorMessage, isLoading }, { apiFunc: verifyAccount }] = useMutation(
    authService.verifyAccount,
    () => {
      if (authState.loggedIn) {
        authDispatch({
          type: SET_USER_VERIFIED
        })
      }
      setLoading(false)
    },
    () => {
      setLoading(false)
    }
  )

  useEffect(() => {
    if (hash) {
      verifyAccount(hash)
    } else {
      push('/signin')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || loading) {
    return <Loader loaderStyle="fullScreen" />
  }

  return (
    <AuthView causesPortalLogo>
      {errorMessage ? (
        <Typography variant="h5" color="secondary">
          {errorMessage}
        </Typography>
      ) : (
        success && (
          <>
            <Typography variant="h5" color="secondary">
              {t('accountVerifiation.emailVerified')}
            </Typography>
            <Typography variant="body2" className={styles.footerText}>
              <span>
                <Link to="/signin">{t('accountVerifiation.clickHereToSignIn')}</Link>
              </span>
            </Typography>
          </>
        )
      )}
    </AuthView>
  )
}
