import React, { useEffect, useRef, useState } from 'react'
import { Grid, Toolbar, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ClaimSearchCardView } from '@percent/cause-dashboard/app/auth/claim/ClaimSearchCardView/ClaimSearchCardView'
import { selectAuthState, SET_ORGANISATION_DETAIL, SET_SELECTED_CHARITY } from '@percent/cause-dashboard/context/auth'
import { Button } from '@percent/lemonade'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { PaginationActions } from '@percent/cause-dashboard/common/components/PaginationActions'
import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { useQueryList, useMutation, useAuthState, useAuthDispatch } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './ClaimSearchResults.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Claim } from '@percent/cause-dashboard/services/claim/claimService.types'
import { Organisation } from '@percent/cause-dashboard/services/cause/causeService.types'
import { OrganizationNotFound } from '../OrganizationNotFound/OrganizationNotFound'
import { RequestToJoinOrganisation } from '../RequestToJoinOrganisation/RequestToJoinOrganisation'
import { SignUpEventName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/causeDashboardAnalytics.types'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'

type ClaimSearchResultsProps = {
  claimResults: {
    selectedCharityCountryCode: string
    selectedCharityName: string
  }
}
export function ClaimSearchResults({ claimResults }: ClaimSearchResultsProps) {
  const { t } = useTranslation()
  const { causeService, claimService } = useServices()
  const [{ data, status, isLoading, errorMessage, totalResults }, { query: search, nextPage, previousPage, refresh }] =
    useQueryList(
      causeService.getCauseSearch,
      { countryCode: claimResults.selectedCharityCountryCode, query: claimResults.selectedCharityName },
      !!claimResults.selectedCharityName
    )
  const { selectedCharityDispatch, authDispatch } = useAuthDispatch()
  const { push } = useHistory()
  const {
    authState,
    selectedCharityState: { selectedCharityName }
  } = useAuthState()
  const { userVerified, claimMade } = selectAuthState(authState)

  useEffect(() => {
    if (data) {
      track(SignUpEventName.SEARCH_RESULTS, {
        countryCode: claimResults.selectedCharityCountryCode,
        query: claimResults.selectedCharityName,
        totalResults
      })
    }
  }, [data])

  const [{ errorMessage: claimErrorMessage, isLoading: isClaimLoading }, { apiFunc: claimCharity }] = useMutation(
    claimService.postVSClaim,
    ({ organisationId }: Claim) => {
      authDispatch({
        type: SET_ORGANISATION_DETAIL,
        payload: {
          claimOrganisation: {
            id: organisationId
          }
        }
      })
      push('/profile')
    }
  )

  const [open, setOpen] = useState(false)
  const [organization, setOrganization] = useState<Organisation | undefined>(undefined)
  const initialRender = useRef(true)
  const errorMsg = errorMessage || claimErrorMessage
  const { track } = useCausesPortalAnalytics()

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      search({
        countryCode: claimResults.selectedCharityCountryCode,
        query: claimResults.selectedCharityName,
        page: 1
      })
    }
  }, [claimResults, search])

  const handleClose = () => {
    setOpen(false)
  }

  const dipatchFunc = (causeName: string | '') =>
    selectedCharityDispatch({
      type: SET_SELECTED_CHARITY,
      payload: {
        selectedCharity: {
          selectedCharityName: causeName,
          selectedCharityCountryCode: claimResults.selectedCharityCountryCode,
          selectedCharityId: organization?.id || ''
        }
      }
    })

  // eslint-disable-next-line consistent-return
  const handleOnClick = async (org: Organisation, selectedResultRank: number) => {
    await track(SignUpEventName.CLAIM_NONPROFIT, {
      organisationName: org.name,
      selectedResultRank,
      countryCode: claimResults.selectedCharityCountryCode,
      query: claimResults.selectedCharityName
    })
    setOrganization(org)
    setOpen(true)
    dipatchFunc(org.name)
  }

  const handleClaim = async () => {
    await track(SignUpEventName.SIGNUP_AND_CLAIM, {
      organisationName: organization?.name
    })
    if (userVerified && !claimMade) {
      claimCharity({
        organisationId: organization?.id || ''
      })
    } else {
      push({
        pathname: '/signup',
        search: `?organization-id=${organization?.id}`
      })
      dipatchFunc(selectedCharityName || '')
    }
  }

  if (isLoading || isClaimLoading) {
    return <Loader />
  }

  if (errorMsg) {
    return <ErrorView errorMessage={errorMsg} />
  }

  if (status === 200 && totalResults === 0) {
    return <OrganizationNotFound />
  }

  if (data) {
    return (
      <>
        <Toolbar>
          <Typography variant="h5" color="secondary" className={styles.claimsText}>
            {totalResults}
            {totalResults > 1 ? ` ${t('typography.charities')} ` : ` ${t('typography.charity')} `}
            found
          </Typography>
        </Toolbar>
        {data?.map((cause, idx) => {
          const address = cause.address && cause.postcode ? `${cause.address} ${cause.postcode}` : 'No address data'

          return (
            <ClaimSearchCardView
              key={cause.id}
              title={cause.name}
              subTitle={address}
              detail={cause.registryId}
              cardAction={
                cause.claimedAt ? (
                  <RequestToJoinOrganisation organisationId={cause.id} organisationName={cause.name} />
                ) : (
                  <div className={styles.claimButtonContainer}>
                    <Button
                      variant="secondary"
                      size="large"
                      data-testid="claim-search-claim-button"
                      onPress={() => handleOnClick(cause, idx)}
                      stretch={true}
                    >
                      {t('button.claimCharity')}
                    </Button>
                  </div>
                )
              }
            />
          )
        })}
        <ModalContainer
          openModal={open}
          onClose={handleClose}
          headerTitle={`${t('dialog.claimSearchResultsTable.title')}`}
        >
          <Grid item xs={12} className={styles.dialogBody}>
            <Typography variant="subtitle1" color="secondary">
              {t('dialog.claimSearchResultsTable.description')}
            </Typography>
            <Typography variant="subtitle1" color="secondary">
              {`${t('dialog.claimSearchResultsTable.subTitle')}`}{' '}
              <span>{`${selectedCharityName || organization?.name}?`}</span>
              {`${t('dialog.claimSearchResultsTable.subTitleHelper')}`}
            </Typography>
            <div className={styles.buttonContainer}>
              <Button
                variant="secondary"
                size="large"
                data-testid="close-claim-charity-btn"
                onPress={() => handleClose()}
              >
                {t('button.cancel')}
              </Button>
              <Button size="large" data-testid="claim-charity-btn" onPress={handleClaim}>
                {t('button.signUpClaim')}
              </Button>
            </div>
          </Grid>
        </ModalContainer>
        <div className={styles.footerCell}>
          <PaginationActions count={totalResults} nextPage={nextPage} previousPage={previousPage} refresh={refresh} />
        </div>
      </>
    )
  }

  return null
}
